/* DEFAULT API CONSTANTS LIKE API or ROOT URLs */
const appConstant = {

/*
    API_URL: 'https://digiseal-portal.secrypt.de/api/',
    API_URL: 'https://digiseal-portal-qs.secrypt.de/api/',
 */
    API_URL: '/api/',
    ROOT_URL: '/#/',
    ACCOUNT_ROOT_URL: '/account#/',
    DASHBOARD_URL: '/account#/digiseal-points',
    AUTHORIZATION: 'Authorization',
    BEARER: 'Bearer',
    TOKEN: 'token',
    REFRESH_TOKEN: 'refresh_token',
    ACCEPT: 'Accept',
    ACCEPT_ENCODING: 'Accept-Encoding',
    MULTIPART_FORM_DATA: 'multipart/form-data',
    APPLICATION_JSON: 'application/json',
    CONTENT_DISPOSITION: 'Content-Disposition',
};

export default appConstant
