import React, {Component} from 'react';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import AppConstant from '../../../constants/app';
import * as authService from '../../../services/authService';
import {clearToken} from "../../../utils/actionUtil";

class UserMenu extends Component {

    componentDidMount()
    {
        axios.post(AppConstant.API_URL + 'account-infos', {})
            .then(res => {
                if (res.data == 'error') {
                    axios.post(AppConstant.API_URL + 'auth/logout', {}).then((response) => {
                        clearToken();
                        window.location.href = AppConstant.ROOT_URL;
                    });
                } else {
                    let data = res.data.data;
                    $('.system-user-name').text(data.username);
                    $('.system-user-name').attr('data-pw', data.pw);
                }
            });
    }

    render() {
        return (
            <div className="userinfo">
				<strong>Angemeldet ist:</strong><br/>
				<span className="system-user-name" data-pw="">-username-</span>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(_.assign({}, authService), dispatch)
    }
}
export default connect(null, mapDispatchToProps)(UserMenu)
