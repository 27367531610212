 
import React, { Component } from 'react';
import AppConstant from '../../constants/app';
import axios from 'axios';

// Import custom components
import AxiosResultHandler from '../common/AxiosResultHandler.component';

class EmailVerifyForm extends Component {

	constructor(props) {
        super(props);

		this.handleSubmitBind = this.handleSubmit.bind(this);

		this.state = {
			commonError: null,
			commonErrorDetails: null,
			submitting: false,
		};
	}

	componentDidMount(){
		document.title = "Registrierung abschließen - secrypt GmbH"
	}

	handleSubmit(event) {
		event.preventDefault();

		//this.setState({submitting:true});

		// reset previous errors
		this.setState({
			commonError:null,
			commonErrorDetails:null,
		});

		var data = {
		};
		const t = this.props.params.signerToken;
		if (t)
			data['signerToken'] = t;

		console.log("posting with ");
		console.log(data);

		axios({
			method: 'post',
			url: AppConstant.API_URL + 'get-signme-identity',
			data: data,
			timeout: 10000, // ms - since this goes to sign-me, the timeout should be a bit longer than with the landing page.
			validateStatus: status => { return true; },
		})
		.then(res => {
			if (res.status == 200 && res.data && res.data.status == "ok")
			{
				// yay! success
				// the answer, directly after registering looks something like:
				// {"person":{"emailAddressVerified":false,"mobilePhoneNumberVerified":false,"identityAdministrativeState":"UNLOCKED","identityVerificationState":"UNVERIFIED"}}
				// the answer after email confirmation looks something like:
				// {"person":{"emailAddressVerified":true,"mobilePhoneNumberVerified":false,"identityAdministrativeState":"UNLOCKED","identityVerificationState":"COMMUNICATION_VERIFIED"}}
				// to combat a problem in the BDR, we do NOT use emailAddressVerified, but instead ONLY use identityVerificationState.
				
				if (res.data.data && res.data.data.person) {
					var p = res.data.data.person;
					if (p.identityVerificationState === "UNVERIFIED") {
						this.setState({commonError:"Sie haben Ihre sign-me E-Mail-Adresse noch nicht bestätigt."});
						this.setState({commonErrorDetails:"Bitte schauen Sie in Ihr E-Mail-Postfach, ob Sie eine E-Mail von D-Trust zur Verifikation Ihres sign-me Accounts erhalten haben. Klicken Sie auf den Link in dieser E-Mail."});
						return;
					}
					if (p.identityVerificationState === "LAWFULLY_VERIFIED_HIGH") {
						this.setState({commonError:"Sie wurden bereits erfolgreich identifiziert."});
						this.setState({commonErrorDetails:"identityVerificationState des Accounts ist bereits LAWFULLY_VERIFIED_HIGH."});
						return;
					}

					const t = this.props.params.signerToken;
					window.location.hash = t ? ("signerToken/startsignmeidentify/" + t) : "startsignmeidentify";
					window.scroll(0,0);
					return;
				}

				this.setState({commonError:"Technischer Fehler: Die Antwort vom Server ist ungültig."});
				return;
			}

			var ret = AxiosResultHandler.ok (res);
			this.setState(ret);
		})
		.catch(error => {
			var ret = AxiosResultHandler.error (error);
			this.setState(ret);
		});
	}

	backToStartBind()
	{
		return () => {
			const t = this.props.params.signerToken;
			window.location.hash = t ? ("signerToken/signme-regid/" + t) : "signme-regid";
			window.scroll(0,0);
			return;
		}
	}

	render() {
		return (
			<div className="content">
				<form method="post" onSubmit={this.handleSubmitBind}>
					<div className="white">
						<div className="container">
							<h1>Registrierung abschließen</h1>
							<p>
								Lassen Sie dazu dieses Fenster geöffnet (<strong>Wichtig!</strong>)
								und führen Sie alle folgenden Schritte aus, um die Registrierung für Ihr sign-me Account
								abzuschließen. Erst danach klicken Sie unten auf den gelben "Weiter"-Button.
							</p>
							<p>
								<ul className="number">
									<li>
										Sie erhalten in Kürze eine E-Mail von D-Trust zur Verifikation Ihres sign-me Accounts.
										Klicken Sie auf den Link in dieser E-Mail.</li>
									<li>
										Es öffnet sich die sign-me Webseite von D-Trust.
										Loggen Sie sich hier mit Ihrer E-Mail-Adresse (sign-me Benutzername) und Passwort ein.
									</li>
									<li>
										Hier muss Ihre Zustimmung erfolgen zu:
										<ul className="dash">
											<li>- den angezeigten Dokumenten (AGB etc.) von D-Trust</li>
											<li>- der Datenschutzerklärung von D-Trust</li>
											<li>- der Erzeugung von Signaturzertifikaten für die Nutzung von sign-me</li>
										</ul>
									</li>
									<li>Klicken Sie auf den "Weiter"-Button in der sign-me Webseite von D-Trust.</li>
									<li>Schließen Sie die sign-me Webseite von D-Trust.</li>
								</ul>
							</p>
							<p>Um mit <strong>sign-me</strong> qualifiziert elektronisch signieren zu können, 
								müssen Sie sich nun noch sicher von D-Trust identifizieren lassen.</p>
							{this.state.commonError &&
								<div>
									<div className="spacer"></div>
									<ul className="errorList">
										<li>Es ist nicht möglich, zum nächsten Schritt zu wechseln.</li>
										<li>{this.state.commonError}</li>
										{this.state.commonErrorDetails && <li className="small">{this.state.commonErrorDetails}</li>}
									</ul>
									<div className="spacer"></div>
								</div>
							}

							<div className="spacer"></div>
							<div className="buttoncontainer">
								<div className="button fixed">
									<button type="button" onClick={this.backToStartBind()}>Abbrechen</button>
								</div>
								<div className="remainder">
								</div>
								<div className="button fixed yellow">
									<button type="submit" disabled={this.state.submitting}>Weiter</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default EmailVerifyForm;

