import React, { Component } from 'react';

import DigiSeal from '../digiSeal.component.jsx';
import HeaderAccount from './headerAccount.component.jsx';
import HeaderPlain from './headerPlain.component.jsx';
import HeaderSignerToken from './headerSignerToken.component.jsx';


class Header extends Component {

	constructor(props) {
        super(props);

		this.handleScrollBind = this.handleScroll.bind(this);

		this.state = {
			showScrollToTop: false,
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScrollBind)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScrollBind)
	}

	handleScroll(event) {
		var yTop = $(window).scrollTop();
		var h = $('header .fixed');
		var isFix = h.is(":visible");
		var mustFix = yTop >= 9; // depends on filler size

		var b = $('header .fixed .bezel');
		var isBezel = b.is(":visible");
		var bannerHeight = $('header').height() - h.height();
		var mustBezel = yTop + 11 >= bannerHeight; // depends on bezel size

		if (isFix && !mustFix)
			h.hide();
		else if (!isFix && mustFix)
			h.show();

		if (isBezel && !mustBezel)
			b.hide();
		else if (!isBezel && mustBezel)
			b.show();

		this.setState({showScrollToTop: yTop > 360}); /* a bit arbitrary... */
	}

	scrollToTop()
	{
		$('html,body').animate({ scrollTop: 0 }, 'slow');
	}

	render(){
		const type = this.props.type;
		var bannerClass = 'banner';
		var HeaderContent = HeaderPlain;

		switch (type) {
			case 'plain':
				HeaderContent = HeaderPlain;
				break;

			case 'signertoken':
				HeaderContent = HeaderSignerToken;
				break;

			case 'auth':
				bannerClass = 'banner account';
				HeaderContent = HeaderAccount;
				break;
		}

		return (
			<header>
				<div className="filler" />
				<HeaderContent />
				<div className='fixed'>
					<HeaderContent />
					<div className="bezel" />
				</div>
				<div className={bannerClass}>
					<div className="container flex">
						<span>
							<img src="./images/icons/identifizieren_white.png" />
						</span>
						<span>
							<div>
								<h1>Registrieren und identifizieren für sign-me Fernsignatur</h1>
								{type != 'signertoken' &&
									<p>und sign-me mit <DigiSeal /> Produktfamilie nutzen</p>
								}
							</div>
						</span>
					</div>
				</div>
				{/* I'll put this here, since the event handler for scrolling is here already */}
				{this.state.showScrollToTop &&
					<div id="scrolltotop" onClick={this.scrollToTop}>
						<span></span>
					</div>
				}
			</header>
       );
    }
}

export default Header