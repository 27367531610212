import React, { Component } from 'react';

class ImpressumForm extends Component {
	componentDidMount(){
		document.title = "Impressum – digiSeal Portal by secrypt GmbH";
	}

    render(){
		return (
			<div className="legal content">
				<div className="grey">
					<div className="container">
						<h1>Impressum für das digiSeal Portal / digiSeal Konto der secrypt GmbH</h1>
						<p>„sign-me“ ist der Fernsignatur-Service der D-Trust GmbH (Ein Unternehmen der Bundesdruckerei). Die secrypt GmbH ist Vertriebspartner der Bundesdruckerei GmbH für sign-me.</p>
						<p>Die sign-me-Registrierungs- und -Identifizierungs-Webseite der secrypt GmbH verlinkt im Verlauf der Prozesse zur Registrierung und Identifizierung direkt auf Webseiten, die im Verantwortungsbereich der D-Trust GmbH liegen. Dort werden die Nutzer zur Zustimmung sign-me-spezifischer Nutzungsbedingungen verpflichtet und es werden umfangreiche personenbezogene Daten erhoben, die zur Erbringung des sign-me Fernsignatur-Services notwendig sind. Beachten Sie die entsprechende Datenschutzerklärung der D-Trust GmbH (Ein Unternehmen der Bundesdruckerei), der Sie im Verlauf des sign-me-Registrierungs- und -Identifizierungs-Prozesses zustimmen müssen.</p>
						<p>Die secrypt GmbH ist Anbieter von Signaturanwendungssoftware zur Nutzung von elektronischen Signaturen, elektronischen Siegeln und elektronischen Zeitstempeln. Sie ist kein qualifizierter Vertrauensdienstanbieter (wie z.B. die D-Trust GmbH), die Herausgeber von elektronischen Signaturen, elektronischen Siegeln und elektronischen Zeitstempeln sind.</p>
					</div>
				</div>

				<div className="white">
					<div className="container">
						<h2>Anbieter</h2>
						<div className="itemcontainer">
							<div className="narrow">
								<p>
									secrypt GmbH<br />
									Hohenzollerndamm 183<br />
									10713 Berlin<br />
									Deutschland
								</p>
								<p>
									Tel.: +49 30 756 59 78-0<br />
									Fax: +49 30 756 59 78-18<br />
									E-Mail: <a href="mailto:mail@secrypt.de">mail@secrypt.de</a><br />
									Webseite: <a href="https://www.secrypt.de" target='_blank'>www.secrypt.de</a>
								</p>
							</div>
							<div className="narrow">
								<p>
									<strong>Geschäftsführer:</strong><br/>
									Tatami Michalek<br/>
									Matthias Schlede
								</p>
								<p>
									<strong>Handelsregistereintrag:</strong><br/>
									Amtsgericht Charlottenburg (Berlin) HRB 83479
								</p>
								<p>
									<strong>Umsatzsteuer-Identifikationsnummer:</strong><br/>
									DE813488645
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="grey">
					<div className="container">
						<h2>Verantwortlich für redaktionelle Inhalte</h2>
						<p>Tatami Michalek</p>
					</div>
				</div>

				<div className="white">
					<div className="container">
						<h2>Verantwortungsbereich</h2>
						<p>Dieses Impressum gilt nur für die Internetpräsenz unter der Adresse:</p>
						<p>
							<a href="https://digiseal-portal.secrypt.de/">https://digiseal-portal.secrypt.de</a>
						</p>
					</div>
				</div>

				<div className="grey">
					<div className="container">
						<h2>Haftung für Inhalte</h2>
						<p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
					</div>
				</div>

				<div className="white">
					<div className="container">
						<h2>Haftung für Links</h2>
						<p>Diese Webseite enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
					</div>
				</div>

				<div className="grey">
					<div className="container">
						<h2>Urheberrecht</h2>
						<p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
					</div>
				</div>

				<div className="white">
					<div className="container">
						<h2>Datenschutz</h2>
						<p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor. Des Weiteren verweisen wir auf unsere ausführlichen Datenschutzbestimmungen.</p>
						<p>Es wird der sign-me-Benutzername (E-Mail-Adresse) gespeichert, was zur Erbringung des sign-me-Fernsignatur-Services notwendig ist.</p>
						<p>Soweit auf unseren Seiten sonstige personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Deshalb steht es jeder betroffenen Person frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch, an uns zu übermitteln.</p>
						<p><b>Diese Webseite benutzt u.a. Cookies, JavaScript und Webformulare.</b></p>
						<p>Bitte beachten Sie unsere <a href="#/datenschutzerklaerung/">Datenschutzerklärung</a>!</p>
						<p>Stand: Juni 2021</p>
					</div>
				</div>

				<div className="grey">
					<div className="container">
						<p>Design: <a href="http://www.diekurfuersten.com/" target='_blank'>DIE KURFÜRSTEN – Agenturen für Werbung</a></p>
					</div>
				</div>
			</div>
		);
	}
}

export default ImpressumForm
