import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

// Import custom components
import identifyReducer from './identifyReducer';
import authReducer from './authReducer';
import crudReducer from './crudReducer';
import apiReducer from './apiReducer';
import flashMessageReducer from './flashMessageReducer';
import { reducer as formReducer } from 'redux-form';
import signerTokenReducer from './signerTokenReducer';

const rootReducer = combineReducers({
	identify: identifyReducer,
	auth: authReducer,
    crud: crudReducer,
    api: apiReducer,
    form: formReducer,  // ← redux-form
    flash: flashMessageReducer,
    routing: routerReducer,
    signerToken: signerTokenReducer,
});

export default rootReducer;