import React, { Component } from 'react';

import SignDoc from './shortHelp.signdocument.component.js';
import SignmeAccount from './shortHelp.signmeAccount.component.js';
import SignmeCertificate from './shortHelp.signmeCertificate.component.js';
import PointsAvailable from './shortHelp.pointsAvailable.component.js';

class Content extends Component {
	
	constructor(props) {
		super(props)
	}
	
	renderChild() {
		
        if (this.props.type == 'signdoc'){
        	return ( <SignDoc /> );
        } else if (this.props.type == 'signmeAccount'){
        	return ( <SignmeAccount /> );
        } else if (this.props.type == 'signmeCertificate'){
        	return ( <SignmeCertificate /> );
        } else if (this.props.type == 'pointsAvailable'){
        	return ( <PointsAvailable /> );
        } else {
        	return (
        			<div>
        				<h2>Willkommen im digiSeal<sup>&reg;</sup>portal der secrypt GmbH</h2>
        				<p>Durch die Nutzung dieses Portals erhalten Sie Zugang zum Signaturservice sign-me der Bundesdruckerei. Mit diesem Service können Sie qualifizierte Signaturen im Web erstellen lassen.</p>
        			</div>
        			);
        }
	}
	
	 render(){
	    	
	        return (
	        	<section className="content-header">
	                { this.renderChild() }
	            </section>
	        );
	    }
	
}

export default Content
