import React, { Component } from 'react';
import {IndexLink} from 'react-router';
import DigiSeal from '../common/digiSeal.component.jsx';

class Title extends Component {
    render(){
        return (
                <section className="content-header">
                    <h1><DigiSeal /> Punkte: Überblick & Aufladen</h1>
                </section>
        );
    }
}

export default Title