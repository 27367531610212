import React, {Component} from 'react';

// Import custom components
import Title from './title.component';

class Statsandusage extends Component {

    render() {
        return (
            <div style={{width:'100%'}}>
                <Title />

                <section>

                    {this.props.children}

                </section>

            </div>
        );
    }
}

export default Statsandusage;
