import React, { Component } from 'react';

class PointsAvailable extends Component {
    render(){
        return (
            <div>
	            
            	<h2>Sie verfügen derzeit über XXX Punkte.</h2>
	            
	            <p>Sie haben folgende Punkte-Kontingente erworben und Ihrem Benutzerkonto
	            zugeordnet.</p>

	            <table className="table table-bordered table-striped" >
	              <caption></caption>
	              <col />
	              <col />
	              <col />
	              <col />
	              <tbody>
	                <tr>
	                  <td>Punkte-Kontingent</td>
	                  <td>Gültig bis</td>
	                  <td>Punkte gesamt</td>
	                  <td>noch verfügbare Punkte</td>
	                </tr>
	                <tr>
	                  <td>s4jjsv1jq849j2u6lbpc2cett</td>
	                  <td>2020-03-22 um 10:58 Uhr</td>
	                  <td>5000</td>
	                  <td>1454</td>
	                </tr>
	                <tr>
	                  <td></td>
	                  <td></td>
	                  <td></td>
	                  <td></td>
	                </tr>
	              </tbody>
	            </table>

	            <p></p>

	            <a href="#">Jetzt neuen Punkte im Webshop kaufen&hellip;.</a>
	            
            </div>
        );
    }
}

export default PointsAvailable