import React, { Component } from 'react';

class NotFoundPage extends Component {
    render(){
        return (
             <div>
                <h1>404 - Dieser Service wurde nicht gefunden</h1>
                <p>Der von Ihnen aufgerufene Service wurde leider nicht gefunden.</p>
             </div>
        );
    }
}

export default NotFoundPage