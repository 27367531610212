import React, { Component } from 'react';

class Title extends Component {
    render(){
        return (
                <section className="content-header">
                {/*
                    <h1>Willkommen im digiSeal signature portal</h1>
                    */}


                </section>
        );
    }
}

export default Title