import React, { Component } from 'react';

import DigiSeal from '../digiSeal.component.jsx';
import AppConstant from "../../../constants/app";
import UserInfo from "./userinfo.component.jsx";

var HeaderContent= (props) => {

	return (
		<div className={props.fixed ? "header fixed" : "header"}>
			<span className="security-logo">
				<img src="./images/security_logo.jpg"></img>
			</span>
			<div className="container app-container">
				<div className='itemcontainer low ownsize'>
					<div className="site-logo">
						<a href="account#/digiseal-points">
							<img src="./images/digiSeal-by-secrypt.svg" alt="secrypt GmbH" />
						</a>
					</div>
					<div className="remainder"></div>
					<div>
						<UserInfo />
					</div>
					<div className="mainmenu mainmenu-account">
						<div className="burgermenu">&nbsp;</div>
						<ul id="menu_content">
							<li className="myaccount-overview">
								<a href={`${AppConstant.ACCOUNT_ROOT_URL}account`}>
									Mein <DigiSeal /> Konto
								</a>
							</li>
							<li className="contingent-overview">
								<a href={`${AppConstant.ACCOUNT_ROOT_URL}digiseal-points`}>
									<DigiSeal /> Punkte
								</a>
							</li>
							<li className="transactionaccounts-overview">
								<a href={`${AppConstant.ACCOUNT_ROOT_URL}point-access-key`}>
									Punkte-Zugangsschlüssel
								</a>
							</li>
							<li className="statsandusage-overview">
								<a href={`${AppConstant.ACCOUNT_ROOT_URL}stats-and-usage`}>
									Statistik Punkte-Verbrauch
								</a>
							</li>
							<li className="new-customer no-padding-top emailnotices-overview">
								<a href={`${AppConstant.ACCOUNT_ROOT_URL}email-notices`}>
									E-Mail-Meldungen
								</a>
							</li>
							<li className="new-customer register-and-signme">
								<a href="./#/signme-regid" target="_blank">
									Registrieren und identifizieren für sign-me Fernsignatur
								</a>
							</li>
							<li className="support-link">
								<a href="https://www.secrypt.de/produkte/support/" target="_blank">
									Support & FAQ
								</a>
							</li>
							<li className="contact-link">
								<a href="https://www.secrypt.de/kontakt/" target="_blank">
									Kontakt
								</a>
							</li>
							<li className="new-customer about-us">
								<a href="https://www.secrypt.de/unternehmen/ueber-uns/" target="_blank">
									Über secrypt
								</a>
							</li>
							<li className="logout-button">
								<a href="#">Abmelden</a>
							</li>
						</ul>
					</div>
					{/* <div className="languageswitch"><ul><li className="active">DE</li><li>EN</li></ul></div> */}
				</div>
			</div>
		</div>
	);
}


export default HeaderContent;