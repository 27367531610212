import React, { Component } from 'react';

class SignmeAccount extends Component {
    render(){
        return (
             
            <div>
            	<h2>Erstellen Sie jetzt Ihren sign-me Account bei der Bundesdruckerei</h2>
	            <p>Zum Erstellen einer Signatur benötigen Sie einen sign-me Account bei der Bundesdruckerei. Die Erstellung dieses Accounts ist für Sie kostenlos und dauert nur wenige Minuten.</p>
            <ol>
            	<li><a href="https://localhost:8085/#/signme/572HGFDA/" target="_blank">Jetzt sign-me Account für mich erstellen (kostenlos).</a></li>
                <li>
                <a href="mailto:?subject=Einladung%20zur%20Fernsignatur&body=Hallo,%0D%0A%0D%0Abitte%20erstelle%20dir%20einen%20Zugang%20zur%20Fernsignatur%20bei%20signme:%20http://localhost:8085/#/signme/572HGFDA/.%0DDieser%20Link%20ist%20gültig%20bis20YY-MM-DD hh.mm.%0D%0A%0D%0AViele%20Grüße">Jetzt sign-me Account für eine andere Person erstellen lassen. Einladung zur kostenlosen Accounterstellung per E-Mail versenden.</a>
                </li>
            </ol>
	            

            </div>
                
        );
    }
}

export default SignmeAccount
