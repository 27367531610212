 
import React, { Component } from 'react';
import AppConstant from '../../constants/app';
import axios from 'axios';

// Import custom components
import AxiosResultHandler from '../common/AxiosResultHandler.component';

function getClassName(basename, props) {
	return basename
		+ (props.error ? " error" : "")
		+ (props.narrow ? " narrow" : "")
		+ (props.fixed ? " fixed" : "")
		+ (props.remainder ? " remainder" : "");
}

var FieldText = (props) => {
	var name = getClassName("textinput", props);
	return (
		<div className={name} id={"signme-"+props.id}>
			<label>{props.name}&nbsp;{props.required && <span className="required" title="erforderlich">*</span>}</label>
			<input name={"signme-"+props.id} type={props.password ? "password" : "text"} autocomplete={props.id}></input>
			{props.error && <div className="error">{props.error}</div>}
		</div>
	);
}

class EnterEmailForm extends Component {

	constructor(props) {
        super(props);

		this.handleSubmitBind = this.handleSubmit.bind(this);

		this.state = {
			errors: {},
			commonError: null,
			commonErrorDetails: null,
		};
	}

	componentDidMount(){
		document.title = "E-Mail-Adresse eintragen - secrypt GmbH"
	}

	isEmail(email) {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

	handleSubmit(event) {
		event.preventDefault();

		var errors = {};

		// reset previous network errors (the regular errors are overwritten below)
		this.setState({
			commonError:null,
			commonErrorDetails:null,
		});

		// extract elements from form
		var email = $('input[name="signme-email"]').val().trim();
		
		if (!email) {
			errors.email = "Dieses Feld darf nicht leer sein.";
		}
		else if (!this.isEmail(email)) {
			errors.email = "Bitte geben Sie eine gültige E-Mail-Adresse ein.";
		}
	
		this.setState({errors:errors});

		var count = Object.keys(errors).length;
		if (count > 0) {
			this.setState({commonError:"Bitte prüfen Sie die Eingabefelder noch einmal."});
			return;
		}

		// all necessary elements have been entered.
		// call sign-me!

		let formData = {};

		formData.emailAddress = email;

		var data = {
			'person': formData
		}
		console.log("posting with ");
		console.log(data);

		axios({
			method: 'post',
			url: AppConstant.API_URL + 'get-signme-identity',
			data: data,
			timeout: 10000, // ms - since this goes to sign-me, the timeout should be a bit longer than with the landing page.
			validateStatus: status => { return true; },
		})
		.then(res => {
			if (res.status == 200 && res.data && res.data.status == "ok")
			{
				if (res.status == 200 && res.data && res.data.status == "ok")
				{
					// yay! success
					// the answer, directly after registering looks something like:
					// {"person":{"emailAddressVerified":false,"mobilePhoneNumberVerified":false,"identityAdministrativeState":"UNLOCKED","identityVerificationState":"UNVERIFIED"}}
					// the answer after email confirmation looks something like:
					// {"person":{"emailAddressVerified":true,"mobilePhoneNumberVerified":false,"identityAdministrativeState":"UNLOCKED","identityVerificationState":"COMMUNICATION_VERIFIED"}}
					// to combat a problem in the BDR, we do NOT use emailAddressVerified, but instead ONLY use identityVerificationState.

					if (res.data.data && res.data.data.person) {
						var p = res.data.data.person;
						if (p.identityVerificationState === "UNVERIFIED") {
							this.setState({commonError:"Sie haben Ihre sign-me E-Mail-Adresse noch nicht bestätigt."});
							this.setState({commonErrorDetails:"Bitte schauen Sie in Ihr E-Mail-Postfach, ob Sie eine E-Mail von D-Trust zur Verifikation Ihres sign-me Accounts erhalten haben. Klicken Sie auf den Link in dieser E-Mail."});
							return;
						}
						if (p.identityVerificationState === "LAWFULLY_VERIFIED_HIGH") {
							this.setState({commonError:"Ihr Account ist schon erfolgreich identifiziert."});
							this.setState({commonErrorDetails:"Der identityVerificationState von Ihrem Account ist schon LAWFULLY_VERIFIED_HIGH."});
							return;
						}

						window.location.hash = "startsignmeidentify";
						window.scroll(0,0);
						return;
					}
	
					this.setState({commonError:"Technischer Fehler: Die Antwort vom Server ist ungültig."});
					return;
				}
			}

			var ret = AxiosResultHandler.ok (res);
			this.setState(ret);
		})
		.catch(error => {
			var ret = AxiosResultHandler.error (error);
			this.setState(ret);
		});
	}

	historyBack()
	{
		window.history.back();
	}

	render() {
		return (
			<div className="content">
				<form method="post" onSubmit={this.handleSubmitBind}>
					<div className="white">
						<div className="container">
							<p>
								Bitte geben Sie ihre E-Mail-Adresse (sign-me Benutzername) ein,
								um Ihre Identifizierung für das qualifizierte Signieren zu starten.
							</p>

							<div className="spacer"></div>

							<div className="singlecontainer">
								<FieldText id="email" name="E-Mail-Adresse (sign-me Benutzername)" error={this.state.errors.email} />
							</div>

							{this.state.commonError &&
								<div>
									<div className="spacer"></div>
									<ul className="errorList">
										<li>Fehler bei der Accountprüfung.</li>
										<li>{this.state.commonError}</li>
										{this.state.commonErrorDetails && <li className="small">{this.state.commonErrorDetails}</li>}
									</ul>
								</div>
							}

							<div className="spacer"></div>
							<div className="buttoncontainer">
								<div className="button fixed">
									<button type="button" onClick={this.historyBack}>Abbrechen</button>
								</div>
								<div className="remainder">
								</div>
								<div className="button yellow fixed">
									<button type="submit" disabled={this.state.submitting}>Weiter</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default EnterEmailForm