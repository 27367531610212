import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash';

import Common from '../../constants/common';

import * as apiAction from '../../actions/apiAction';
import * as crudAction from '../../actions/crudAction';
import * as flashMessage  from '../../actions/flashMessage';

import {Field, reduxForm} from "redux-form";
import axios from "axios";
import AppConstant from "../../constants/app";

class EmailnoticesConfirm extends Component {

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        this.props.actions.clearList(Common.NOTIFICATIONS);
        this.props.actions.apiClearState();
    }

    componentDidMount() {
        document.title = "E-Mail-Bestätigung – digiSeal Portal by secrypt GmbH"
        let token = this.props.params.id;

        var data = {};
        data.token = token;
        axios.post(AppConstant.API_URL + 'email-change-confirm', {data})
            .then(res => {
                var message = '';
                if(res.data.code != undefined && res.data.code == 'error'){
                    switch(res.data.message.code) {
                        case "-1":
                            message = 'Fehler : Es ist ein unbekannter Fehler aufgetreten.';
                            break;

                        case "1":
                            message = 'Fehler : Ihr Bestätigungstoken ist leider bereits abgelaufen.';
                            break;

                        case "2":
                            message = 'Fehler : Dieser Bestätigungstoken ist leider unbekannt.';
                            break;

                        case "3":
                            message = 'Fehler : Ihre E-Mail Adresse konnte nicht geändert werden.';
                            break;
                    }
                }
                else{
                    if(res.data.code != undefined && res.data.code == 'ok') {
                        message = 'Ihre E-Mail Adresse wurde erfolgreich geändert.'
                    }
                }

                $('.info-output-email-change').html(message );
            })
			.catch(res => {
                var message = '';
				if (res.response && res.response.status == 401) {
					message = 'Fehler : Sie sind nicht angemeldet.';
				}
				else {
					message = 'Fehler : Es ist ein unbekannter Fehler aufgetreten.';
				}

                $('.info-output-email-change').html(message );
			});
    }

	render() {
		return (
			<div className="content">
				<div className="grey">
					<div className="col-12 emailnotifications-container">
						<h3 className="info-output-email-change">

						</h3>
					</div>
				</div>

				<div className="white">
					<div className="container center">
						<div className="spacer" />
					</div>
				</div>
			</div>
		);
	}
}

EmailnoticesConfirm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
	data: PropTypes.array,
	columns: PropTypes.array,
	options: PropTypes.object,
};


/**
 * Map the state to props.
 */
function mapStateToProps(state) {
    return {
        notifications: state.crud.notifications,
        apiState: state.api,
        message: state.flash.message,
        selectedItem: state.crud.selectedItem,
        initialValues: state.crud.selectedItem.notifications
    }
}

/**
 * Map the actions to props.
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(_.assign({}, crudAction, apiAction, flashMessage), dispatch)
    }
}

/**
 * Connect the component to the Redux store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'EmailnoticesConfirm', // ←A Unique identifier for this form
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(EmailnoticesConfirm))
