import React, {Component} from 'react';

class Header extends Component {

    render() {

        let columns = this.props.columns; // [{displayName, attribute}]
        let myClassName = this.props.class;

        return (
            <thead className={myClassName}>
                <tr>
                    {
                        columns.map((column, index) => (
                            <th key={index} className={column.align ? 'align-'+column.align : ''} > {column.displayName} </th>
                        ))
                    }
                </tr>
            </thead>
        );
    }
}

export default Header