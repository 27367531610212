
import React, { Component } from 'react';

var Schnellanleitung= (props) => {
	return (
		<a
			className={props.className}
			href={props.dsOffice
					? "https://www.secrypt.de/download-file/checkliste-sign-me-mit-digiseal-office/"
					: "https://www.secrypt.de/download-file/checkliste-schnellanleitung-registrieren-und-identifizieren-fuer-die-qualifizierte-sign-me-fernsignatur/"
			}
			target='_blank'
		>
			<span>
				{props.children}
			</span>
		</a>
	);
}

export default Schnellanleitung;

