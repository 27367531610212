import React, { Component } from 'react';
import {IndexLink} from 'react-router';

class Title extends Component {
    render(){
        return (
                <section className="content-header">
                    <h1>Punkte-Zugangsschlüssel: Erzeugen & Verwalten</h1>
                </section>
        );
    }
}

export default Title