import React, { Component } from 'react';

class SignDoc extends Component {

    render(){
    	
    	// get transaction account
    	
        return (
             
        	<div>
	            <h2>Mit dem digiSeal office signieren</h2>
	            
	            <p>Verwenden Sie digiSeal office und erstellen Sie eine Fernsignatur über ihr Punktekonto. Dazu müssen folgende Schritte ausgeführt werden:</p>

	            <ol>
	            	<li>digiSeal office erwerben und installieren</li>
	                <li>Kopieren Sie den folgenden Punkte-Zugangsschlüssel XXXX und hinterlegen Sie diesen im digiSeal office.
	                <ol>
	                	<li>
		                <a href="#" class="btn btn-primary">Punkte-Zugangsschlüssel in Zwischenablage kopieren</a>
	                	</li>
	                	<li>
	                	<a href="mailto:?subject=Punkte-Zugangsschlüssel%20für%20die%20Fernsignatur&body=Hallo,%0D%0A%0D%0Ahinterlegen%20Sie%20folgenden%20Zugriffschlüssel%20im%20digiSeal%20office,%20um%20die%20Fernsigantur%20verwenden%20zu%20können.%0D%0A%0D%0AViele%20Grüße">Punkte-Zugangschlüssel per E-Mail versenden.</a>
	                	</li>
	                </ol>
	                
	                </li>
	            </ol>
            </div>
                
        );
    }
}

export default SignDoc