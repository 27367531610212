import React, { Component } from 'react';

class Title extends Component {
    render(){
        return (
                <section className="content-header">
                    <h1>E-Mail-Meldungen</h1>
                </section>
        );
    }
}

export default Title