import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import AppConstant from "../../../constants/app";
import {clearToken} from "../../../utils/actionUtil";

class Body extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $('.logout-button').off('click').on('click', function(e){
            $.confirm({
                title: 'Logout',
                btnClass : 'cancel-btn',
                content: 'Möchten Sie sich jetzt wirklich ausloggen?',
                buttons: {
                    ok: {
                        text : 'OK',
                        btnClass : 'btn modal-editor-btn btn-primary',
                        action: function () {
                            axios.post(AppConstant.API_URL + 'auth/logout', {}).then((response) => {
                                clearToken();
                                window.location.href = AppConstant.ROOT_URL;
                            });
                        }
                    },
                    abbrechen: {
                        text : 'Abbrechen',
                        btnClass : 'cancel-btn',
                        action: function () {}
                    },
                }
            });
            return false;
        })
        $('.consumptiontable').next('table').find('thead').addClass('consumption');
    }

    render() {
        let columns = this.props.columns, // [{displayName, attribute}]
            data = this.props.data,
            options = this.props.options;
        let foo = [];
        if (data[0] == undefined) {
            foo[0] = data;
        } else {
            foo = data;
        }

        let myClassName = this.props.class;
        let myDataId = this.props.dataid;

		return (

			<tbody className={myClassName}>
			{
				foo.map(function (item) {
					let cells = columns.map(function (column, index) {
						return (
							<td key={index} className={column.align ? 'align-'+column.align : ''}>
								{(() => {
									if (column.attribute == '') {
										if (options.model == 'transactionaccounts') {
											return <a data-id={""+item.id+""} href="#" title="Bearbeiten" className="edit-transaction-btn">
												<i className="fa-pencil fa"></i></a>
										}
										else {
											return <a href={"#/" + options.model + "/" + item.id} title="Bearbeiten">
												<i className="fa-pencil fa"></i></a>
										}
									}
									else if (column.attribute == 'userActivationStatus' || column.attribute == 'validityStatus') {
										let classNames = '';
										let status = '';
										if (item[column.attribute] != null) {
											classNames = item[column.attribute] == 1 ? "label label-success" : "label label-warning";
											status = item[column.attribute] == 1 ? "Aktiv" : "Inaktiv";
										}
							
										return <span className={classNames}>{status}</span>;
									}
									else {
										return item[column.attribute];
									}
								})()}
							</td>
						);
					});
					return <tr key={item.id}>{cells}</tr>;
				})
			}
			</tbody>
		);
	}
}

export default Body;
