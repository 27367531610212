import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";
import cookie from "react-cookie";
import _ from 'lodash';

/**
 * Import all constants as an object.
 */
import Common from '../../constants/common';

/**
 * Import all apiAction and crudAction as an object.
 */
import * as apiAction from '../../actions/apiAction';
import * as crudAction from '../../actions/crudAction';
import * as flashMessage  from '../../actions/flashMessage';

// Import custom components
import DataTable from '../common/table/table.component';
import FlashMessage from '../common/flash/message.component';
import AppConstant from "../../constants/app";
import largestRemainderRound from '../../utils/largestRemainderRound.js';
import axiosGetStatistics from './axiosGetStatistics.js';
import StatisticsTable from './statisticsTable.component.jsx';

class StatsandusageList extends Component {

    constructor(props) {
        super(props);

		this.state = {
			statistics: undefined,
		};

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
        this.handleLoad();
        this.fetchData();
    }

    componentWillUnmount() {
        this.props.actions.clearList(Common.CONTINGENT);
        this.props.actions.removeFlashMessage();
        this.props.actions.apiClearState();
    }

    fetchData() {
        this.props.actions.fetchAll(Common.CONTINGENT);
    }

    componentDidMount() {
        document.title = "Statistik Punkte-Verbrauch – digiSeal Portal by secrypt GmbH";
    }

    handleChange(event) {
        var key = event.target.name;
        var value = event.target.value;
        this.props.actions.updateSelectedItem(Common.CONTINGENT, key, value);
    }

    reloadLocation(){
        window.location.reload();

       return false;
    }
	
    handleLoad() {

        var token = cookie.load(AppConstant.TOKEN);

        // load + handle statistic data
		axiosGetStatistics.getTotal (token, statistics => {
			this.setState({ statistics });

			var haveStatistics = false;
			statistics && statistics.years && statistics.years.forEach( (year, index) => {

				var containerData = '<ul>';
				let totalActions = year.ID_QES + year.QES + year.ADV + year.BAS + year.QTSP;
				var idQesPercent = year.ID_QES * 100 / totalActions;
				var qesPercent = year.QES * 100 / totalActions;
				var advPercent = year.ADV * 100 / totalActions;
				var basPercent = year.BAS * 100 / totalActions;
				var qtspPercent = year.QTSP * 100 / totalActions;

				var numbers = [idQesPercent, qesPercent, advPercent, basPercent, qtspPercent];
				numbers = largestRemainderRound (numbers, 100);

				containerData += '<li>' + year.year + '</li>';
				containerData += '<li>';
				containerData += '<div class="statistic-bar">';
				containerData += '  <ul>';

				[
					[ numbers[0], 'idqes-stats-bar' ],
					[ numbers[1], 'qes-stats-bar' ],
					[ numbers[2], 'adv-stats-bar' ],
					[ numbers[3], 'bas-stats-bar' ],
					[ numbers[4], 'qtsp-stats-bar' ],
				].forEach (([number, className]) => {
					if (number > 0) {
						var numberOutput = number < 3 ? '' : number + "%";
						containerData += '    <li class="' + className + '" style="width:' + number + '%" title="' + number + '%"><span>' + numberOutput + '</span></li>';
					}
				});

				containerData += '  </ul>';
				containerData += '</div>';
				containerData += '</li>';
				containerData += '</ul>';

				if (totalActions > 0) {
					$('.statistic-bars-container').append(containerData);
					haveStatistics = true;
				}
			});
			if (!haveStatistics) {
				$('.statistic-bars-container').prepend('<span>Es liegen noch keine Daten vor</span>');
			}
		});
    }

    render() {

        let data = this.props.contingents;
        let message = this.props.message;
		let statistics = this.state.statistics;

        return (
                <div className="col-12 statsandusage-container">

                    <FlashMessage message={message} />
                    <div className="button margin-top-20px margin-bottom-20px">
						<button className="btn btn-primary width-200px refresh-btn" onClick={this.reloadLocation}>Aktualisieren</button>
					</div>
                    <h4 className="subheadline">Übersicht</h4>
                    <DataTable columns={columns} data={data} options={options} loadingText="Ihre Punkte-Kontingente werden geladen..." />

                    <div className="col-12">
                        <h4 className="subheadline">Häufigkeit der Aktionen in %</h4>
                        <div className="statistic-bars-container">
                        </div>
                    </div>

					<StatisticsTable statistics={statistics} />
                </div>
        );
    }
}

StatsandusageList.propTypes = {
	data: PropTypes.array,
	columns: PropTypes.array,
	options: PropTypes.object,
};

/**
 * Map the state to props.
 */
function mapStateToProps(state) {
    return {
        contingents: state.crud.contingents,
        apiState: state.api,
        message: state.flash.message,
        selectedItem: state.crud.selectedItem,
        initialValues: state.crud.selectedItem.contingents
    }
}

/**
 * Map the actions to props.
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(_.assign({}, crudAction, apiAction, flashMessage), dispatch)
    }
}

const columns = [
    {displayName: 'ID', attribute: 'id'},
    {displayName: 'Kontingent (verwendeter Coupon (CPN))', attribute: 'key', align: 'left'},
    {displayName: 'Erworbene Punkte', attribute: 'points'},
    {displayName: 'Aktivierungs- datum', attribute: 'validitySince'},
    {displayName: 'Ablauf- datum', attribute: 'validityUntil'},
    {displayName: 'Verbrauchte Punkte', attribute: 'pointsConsumed'},
    {displayName: 'Verbleibende Punkte', attribute: 'pointsRemaining'}
];

const options = {model: 'contingents'};

/**
 * Connect the component to the Redux store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'ContingentForm2', // ←A Unique identifier for this form
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(StatsandusageList))
