import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import _ from 'lodash';
import AppConstant from '../../constants/app';
import cookie from 'react-cookie';
import DigiSeal from '../common/digiSeal.component.jsx';
import * as authService from '../../services/authService';
import * as flashMessage  from '../../actions/flashMessage';
import FlashMessage from '../common/flash/message.component';
import renderText from '../login/renderText';

class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        if (cookie.load(AppConstant.TOKEN)) {
            window.location.href = AppConstant.ACCOUNT_ROOT_URL + "digiseal-points";
        }
    }

    componentWillMount() {
        window.$('body').addClass('hold-transition login-page');
    }

    componentWillUnmount() {
        this.props.actions.removeFlashMessage();
    }

    componentDidMount() {
        document.title = "Konto Login – digiSeal Portal by secrypt GmbH";
        const password = document.getElementsByName("password");
        const togglePassword = document.querySelector('#togglePassword');

        togglePassword.addEventListener('click', function (e) {
            const type = password[0].getAttribute('type') === 'password' ? 'text' : 'password';
            password[0].setAttribute('type', type);
            this.classList.toggle('dash');
        });
    }

    handleSubmit(formProps) {
        this.props.actions.login(formProps);
    }

	render() {

		let message = this.props.message;
		const {handleSubmit, submitting} = this.props;

		return (
			<div className="content">
				<div className="login-box">
					<div className="login-box-body">
						<h3><DigiSeal /> Konto Anmeldung</h3>
						<p className="login-box-msg">
							Im <DigiSeal /> Konto können Sie Ihre <DigiSeal /> Punkte verwalten und
							überwachen. <DigiSeal /> Punkte dienen der Abrechnung der sign-me
							Fernsignatur in Kombination mit der	Signatursoftware <DigiSeal /> office (pro).
							<br/>
						</p>
						<p className="login-box-msg">
							<a className="arrow" href="https://www.secrypt.de/loesungen/fernsignatur-handy-signatur/" target="_blank">
							Hier informieren: Wie funktionieren <DigiSeal /> Punkte?</a>
						</p>
						<p className="login-box-msg">Sie haben noch kein <DigiSeal /> Konto? <a className="arrow" href="./#/create">Erstellen</a></p>

						<FlashMessage message={message}/>

						<form method="post" onSubmit={handleSubmit(this.handleSubmit)}>
							<Field
								name="username"
								component={renderText}
								type="text"
								label="Benutzername"
							>
							</Field>

							<Field
								name="password"
								component={renderText}
								type="password"
								label="Passwort"
							>
							<i className="eye togglePassword" id="togglePassword"></i>

							</Field>

							<div className="row">
								<div className="col-xs-8">
									<a href="#/resetpw/" className="resetPw arrow">Passwort vergessen?</a>
								</div>
								<div className="col-xs-4 button">
									<button type="submit" className="btn btn-primary btn-block btn-flat" disabled={submitting}>Anmelden</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}


LoginForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

/**
 * Map the state to props.
 */
function mapStateToProps(state) {
    return {
        token: state.auth.token,
        isAuthenticated: state.auth.isAuthenticated,
        message: state.flash.message
    }
}

/**
 * Map the actions to props.
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(_.assign({}, authService, flashMessage), dispatch)
    }
}

const validateLogin = values => {
    const errors = {};
    if (!values.username) {
        errors.username = '(Bitte geben Sie einen Benutzernamen ein.)'
    }

    if (!values.password) {
        errors.password = '(Bitte geben Sie ein Passwort ein.)'
    }
    return errors
};

/**
 * Connect the component to the Redux store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'LoginForm', // ←A Unique identifier for this form
    validate: validateLogin  // ←Callback function for client-side validation
})(LoginForm))
