import React, {Component} from 'react';

class Statusbar extends Component
{
	constructor(props) {
		super(props);
	}

	render() {

		let data = this.props.data;

		var pointsTotal = 0;
		var usablePointsTotal = 0;
		var pointsRemaining = 0;
		var pointsConsumed = 0;
		data && data.forEach && data.forEach(item => {
			pointsTotal += parseFloat(item.points);
			pointsConsumed += parseFloat(item.pointsConsumed);
			if (!item.expiredByDate) {
				usablePointsTotal += parseFloat(item.points);
				pointsRemaining += parseFloat(item.pointsRemaining);
			}
		});
		var barUsed = parseInt(pointsRemaining * 60 / usablePointsTotal);
		console.log (barUsed);
		var imgs = [];
		for (var i = 1; i <= 60; i++) {
			if (i <= barUsed) {
				imgs.push(<img key={i} src="./images/icons/stats_icon_on.png" />);
			} else {
				imgs.push(<img key={i} src="./images/icons/stats_icon_off.png" />);
			}
		}

		var percentRemaining = Math.round(100 * pointsRemaining / usablePointsTotal);

		// HIDEOUS but difficult to change
		// just do not change the table format...
		setTimeout(function () {
			$('.statsheader').remove();
			$('.dataTable thead').append(
				'<tr class="statsheader"><td></td><td>Gesamt</td><td>' + pointsTotal + '</span></td><td></td><td></td><td>' + pointsConsumed + '</td><td>' + pointsRemaining + '</td></tr>'
			);
		}, 500);

		return (
			<div>
				<ul className="contingentStatsHeadline">
					<li>Verbleibende Punkte*:</li>
					<li>Erworbene Punkte*:</li>
				</ul>

				<div className="contingent_stats_output">{imgs}</div>

				<ul className="contingentStatsFooter">
					<li>
						<span className="percent">{percentRemaining}%</span><br/>
						<span className="value">{pointsRemaining}</span>
					</li>
					<li>
						<span className="percent">100%</span><br/>
						<span className="value">{usablePointsTotal}</span>
					</li>
				</ul>

				<div className="contingent-info-title">
					*Nicht abgelaufene Kontingente
				</div>
			</div>
		);
	}
}

export default Statusbar;
