
var initialState = {
	token: undefined,
	name: undefined,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case 'signerToken/token':
			var s = {...state};
			s.token = action.token;
			return s;

		case 'signerToken/name':
			var s = {...state};
			s.name = action.name;
			return s;

		default:
			return state;
	  }
}
