
var initialState = {
        url: undefined,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case 'url':
		  return Object.assign({}, state, {
			url: action.url
		  });
		default:
		  return state;
	  }
}