import React, { Component } from 'react';

import DigiSeal from '../digiSeal.component.jsx';
import AppConstant from "../../../constants/app";

var HeaderContent= (props) => {

	return (
		<div className={props.fixed ? "header fixed" : "header"}>
			<span className="security-logo">
				<img src="./images/security_logo.jpg"></img>
			</span>
			<div className="container">
				<div className='itemcontainer low ownsize'>
					<div className="site-logo">
						<a href={`${AppConstant.ROOT_URL}`}>
							<img src="./images/digiSeal-by-secrypt.svg" alt="secrypt GmbH" />
						</a>
					</div>
					<div className="remainder"></div>
					<div className="mainmenu">
						<div className="burgermenu">&nbsp;</div>
						<ul id="menu_content">
							<li><div className="button"><a href="#/login">Anmelden</a></div></li>
							<li>
								<strong>Neuer Kunde?</strong><br/>
							</li>
							<li className="new-customer add-user">
								<a  href="./#/create">Jetzt <DigiSeal /> Konto erstellen</a>
							</li>
							<li className="new-customer register-and-signme">
								<a href="./#/signme-regid">
									Registrieren und identifizieren für sign-me Fernsignatur
								</a>
							</li>
							<li className="support-link">
								<a href="https://www.secrypt.de/produkte/support/" target="_blank">
									Support & FAQ
								</a>
							</li>
							<li className="contact-link" >
								<a href="https://www.secrypt.de/kontakt/" target="_blank">
									Kontakt
								</a>
							</li>
							<li className="about-us">
								<a href="https://www.secrypt.de/unternehmen/ueber-uns/" target="_blank">
									Über secrypt
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default HeaderContent;