import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash';
import DigiSeal from '../common/digiSeal.component.jsx';

/**
 * Import all constants as an object.
 */
import Common from '../../constants/common';

/**
 * Import all apiAction and crudAction as an object.
 */
import * as apiAction from '../../actions/apiAction';
import * as crudAction from '../../actions/crudAction';
import * as flashMessage  from '../../actions/flashMessage';

import DataTable from '../common/table/table.component';
import Statusbar from './statusbar.component';
import {Field, reduxForm} from "redux-form";
import axios from "axios";
import AppConstant from "../../constants/app";

class ContingentList extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.fetchData();
        setTimeout( function() {
            $('input[name="contingentKey"]').unbind();
        }, 400 )
    }

    componentWillUnmount() {
        this.props.actions.clearList(Common.CONTINGENT);
        this.props.actions.removeFlashMessage();
        this.props.actions.apiClearState();
    }

    fetchData() {
        this.props.actions.fetchAll(Common.CONTINGENT);
    }

    componentDidMount() {
        document.title = "digiSeal Punkte – digiSeal Portal by secrypt GmbH"

        $('input[name="contingentKey"]').unbind();
        $('.add-coupon-code-btn').off('click').on('click', function(){

            var contingentKey = $('input[name="contingentKey"]').val();
            if (!contingentKey) {
                $('input[name="contingentKey"]').next('.input-info-output').addClass('error').html('Bitte geben Sie den Couponcode ein.');
                return false;
            }

            var data = {};
            data.contingentKey = contingentKey;
            axios.post(AppConstant.API_URL + 'contingent', {data})
                .then(res => {
                    if (res.data.status == 'error') {
                        $.toast({
                            heading: 'Fehler beim Punkte nachladen',
                            text: 'Die Punkte konnten Ihrem Account nicht zugewiesen werden. Bitte prüfen Sie Ihre Eingabe.',
                            showHideTransition: 'slide',
                            position: 'top-right',
                            icon: 'error',
                            allowToastClose: true,
                            hideAfter: 10000,
                            bgColor: '#c01818'
                        })
                    } else {
                        $.toast({
                            heading: 'Punktekontingent aktualisiert',
                            text: 'Ihre Änderungen wurden erfolgreich gespeichert.',
                            showHideTransition: 'slide',
                            position: 'top-right',
                            icon: 'success',
                            allowToastClose: true,
                            hideAfter: 3000,
                            bgColor: '#6cbf00'
                        })
                    }
                });
        })
    }

    handleChange(event) {
        var key = event.target.name;
        var value = event.target.value;
        this.props.actions.updateSelectedItem(Common.CONTINGENT, key, value);
    }

    render() {

        let data = this.props.contingents;

        return (
            <div className="col-12 contingent-container">
				<p>
					Im <DigiSeal /> Konto können Sie Ihre <DigiSeal /> Punkte verwalten und
					überwachen. <DigiSeal /> Punkte dienen der transaktionsbasierten Abrechnung der sign-me
					Fernsignatur und des D-Trust Zeitstempels in Kombination mit der Signatursoftware <DigiSeal /> office (pro).
					<br/>
				</p>
				<p>
					<a className="arrow" href="https://www.secrypt.de/loesungen/fernsignatur-handy-signatur/" target="_blank">
					Hier informieren: Wie funktionieren <DigiSeal /> Punkte?</a>
				</p>
                <p>
                    Coupons zur Erweiterung Ihres Punkte-Kontingents erwerben:&nbsp;
                    <a className="arrow" href="https://shop.secrypt.de/produkt/digiseal-punkte/" target="_blank">Online-Shop</a> oder <a className="arrow" href="https://www.secrypt.de/kontakt/" target="_blank">Kontakt</a>.
                </p>

                <div className="col-md-12 no-padding padding-top-20px padding-bottom-20px">
                    <div className="form-horizontal">
                        <label className="col-md-10 control-label"><img src="./images/icons/ticket.png" alt="" className="ticket-icon" />Punkte nachladen:</label>
                        <div className="col-md-8 no-padding">
                            <div className="data-container">
                                <input type="text" name="contingentKey" className="form-control" placeholder="Bitte Couponcode eingeben (CPN-...)" />
                                <span className="input-info-output"></span>
                            </div>
                        </div>
                        <div className="col-xs-4 button">
                            <button type="submit" className="btn btn-primary add-coupon-code-btn">Coupon einlösen</button>
                        </div>
                    </div>
                </div>

				{data && <Statusbar data={data} />}

				<DataTable
					columns={columns}
					data={data} options={options}
					loadingText="Ihre Punkte-Kontingente werden geladen..."
					emptyText="Aktuell haben Sie keine Kontingente"
				/>
            </div>
        );
    }
}

/**
 * Map the state to props.
 */
function mapStateToProps(state) {
    return {
        contingents: state.crud.contingents,
        apiState: state.api,
        message: state.flash.message,
        contingentKey: ''
    }
}

/**
 * Map the actions to props.
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(_.assign({}, crudAction, apiAction, flashMessage), dispatch)
    }
}

const columns = [
    {displayName: 'ID', attribute: 'id'},
    {displayName: 'Kontingent (verwendeter Coupon (CPN))', attribute: 'key'},
    {displayName: 'Erworbene Punkte', attribute: 'points'},
    {displayName: 'Aktivierungs- datum', attribute: 'validitySince'},
    {displayName: 'Ablauf- datum', attribute: 'validityUntil'},
    {displayName: 'Verbrauchte Punkte', attribute: 'pointsConsumed'},
    {displayName: 'Verbleibende Punkte', attribute: 'pointsRemaining'}
];

const options = {model: 'contingents'};

/**
 * Connect the component to the Redux store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'ContingentForm', // ←A Unique identifier for this form
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(ContingentList))
