import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Import custom components
import Header from './header.component';
import Body from './body.component';

class Table extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // Get the DOM node and store the jQuery element reference
        this.$node = $(this.refs.datatable);
        this.$node.dataTable({
            "oLanguage": {"sSearch": ""},
            "aaSorting": [[0, "desc"]],
            "pageLength" : 50,
            "oLanguage": {
                "sProcessing":   "Bitte warten...",
                "sZeroRecords":  "Keine Daten in der Datenbank",
                "sInfo":         "Zeige _START_ bis _END_ von _TOTAL_ Einträgen",
                "sInfoEmpty":    "0 bis 0 von 0 insgesamt",
                "sInfoFiltered": "",
                "sInfoPostFix":  "",
                "sSearch":       "Suche",
                "sUrl":          "",
            },
        });
        // once mounted, hide loading-info
        jQuery('.loadingInfo').animate({opacity: 0}, 800).slideUp();
    }

    shouldComponentUpdate()
    {
        // rebuild table
        // this.$node.dataTable({
        //     retrieve: true,
        //     paging: false
        // });

        return true;
    }

    componentWillUnmount() {
        // Clean up the mess when the component unmounts
		this.$node.dataTable().api().destroy();
    }
    componentWillReceiveProps(nextProps) {
        // return this.props !== nextProps
    //     this.$node.dataTable({
    //         "oLanguage": {"sSearch": ""},
    //         "aaSorting": [[1, "desc"]],
    //         "aoColumnDefs": [
    //             {'bSortable': false}
    //         ],
    //         "bDestroy": true
    //     });
    }

    render() {

        return (
            <table ref="datatable" className="table table-bordered table-striped">
                <Header {...this.props}/>
                <Body {...this.props}/>
            </table>
        );
    }
}

class Text extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        jQuery('.loadingInfo').animate({opacity: 0}, 800).slideUp();
    }
    render() {
        return (<div>{this.props.text}</div>);
    }
}

var TableHelper = (props) => {
	return (
		<div>
			<div className="loadingInfo">{props.loadingText}</div>
			<div className="box-body">
				{
					props.data
					&& props.data.length > 0
					&& <Table columns={props.columns} data={props.data} options={props.options}/>
				}
			</div>
			<div className="emptyInfo">
				{
					props.data
					&& props.data.length == 0
					&& <Text text={props.emptyText ? props.emptyText : 'Keine Daten vorhanden'} />
				}
			</div>
		</div>
	);
}

export default TableHelper
