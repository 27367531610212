import React, { Component } from 'react';
import AppConstant from '../../constants/app';
import axios from 'axios';

// Import custom components
import AxiosResultHandler from '../common/AxiosResultHandler.component';
import DigiSeal from '../common/digiSeal.component.jsx';
import Schnellanleitung from '../common/Schnellanleitung.component.jsx';

class LandingForm extends Component {
    constructor(props) {
        super(props);

		this.state = {
			commonError: null,
			commonErrorDetails: null,
			pointKey: '',
			pointKeyInfoVisible: false,
			pointKeyVisible: false,
		};
	}

	componentDidMount(){
		document.title = "Registrieren und Identifizieren für sign-me Fernsignatur - digiSeal Portal by secrypt GmbH"
		$('.hold-transition').removeClass('login-page');
	}

	handleSubmitBind(arg) {
		return event => {
			this.handleSubmit (event, arg);
		}
	}

	handleSubmit(event, arg) {
		event.preventDefault();

		// reset previous errors
		this.setState({
			commonError:null,
			commonErrorDetails:null,
		});

		var v = this.state.pointKey;

		if (typeof v !== 'string') {
			this.setState({commonError:"Technischer Fehler: Interner JavaScript Fehler."});
			return;
		}

		v = v.trim();
		if (v === "") {
			this.setState({commonError:'Der Zugangsschlüssel ist ein Pflichtfeld.'});
			return;
		}

		v = v.toUpperCase();
		if (!v.startsWith("PAK-")) {
			this.setState({commonError:'Ein gültiger Zugangsschlüssel muss mit „PAK-“ anfangen.'});
			return;
		}

		if (v.length < 29) {
			this.setState({commonError:'Der Zugangsschlüssel ist zu kurz. Haben Sie den Schlüssel vollständig eingetragen?'});
			return;
		}

		// check if this pointAccessKey is valid
		var data = {
			'pointAccessKey': v
		}
		axios({
			method: 'post',
			url: AppConstant.API_URL + 'login-with-pointaccesskey',
			data: data,
			timeout: 4000, // ms
			validateStatus: status => { return true; },
		})
		.then(res => {
			if (res.status == 200 && res.data && res.data.status == "ok")
			{
				// we have a result. See if we have a correct point access key in our hands.
				if (!res.data.data || res.data.data.id === undefined || res.data.data.id == null) {
					this.setState({commonError:"Der eingetragene Punkte-Zugangsschlüssel ist nicht bekannt."});
					return;
				}

				// yay! success
				window.location.hash = (arg =='create') ? 'createsignmeaccount' : 'enteremail';
				window.scroll(0,0);
				return;
			}

			var ret = AxiosResultHandler.ok (res);
			this.setState(ret);
		})
		.catch(error => {
			var ret = AxiosResultHandler.error (error);
			this.setState(ret);
		});
	}

	handleKeyDownBind()
	{
		return () => {
			// reset previous errors
			this.setState({
				commonError:null,
				commonErrorDetails:null,
			});
		}
	}

	handlePointKeyChange = (e) => {
		this.setState({ pointKey: e.target.value });
	}

	onPointKeyVisiblity = () => {
		var s = this.state.pointKeyVisible;
		this.setState({ pointKeyVisible:!s });
	}

	pointKeyInfoVisibilityBind(state)
	{
		return () => {
			this.setState({pointKeyInfoVisible:state});
		}
	}

	render() {
		return (
			<div id='landingpage' className="content">
				<div className="grey">
					<div className="container emphasis">
						<p>
							<strong>„sign-me“</strong> ist der Fernsignatur-Service der D-Trust GmbH (Ein Unternehmen der Bundesdruckerei),
							den Sie in Kombination mit der 
							Signatursoftware <a className="arrow" href="https://www.secrypt.de/produkte/digiseal-office/" target='_blank'><DigiSeal />office </a>
							&nbsp;und/oder <a className="arrow" href="https://www.secrypt.de/produkte/digiseal-web/" target='_blank'><DigiSeal />web </a>
							&nbsp;der <a className="arrow" href="https://www.secrypt.de" target='_blank'>secrypt GmbH</a> nutzen können.
						</p>
						<p>
							<ul>
								<li>Mit <strong><DigiSeal />office</strong> und <strong><DigiSeal />web</strong> sind alle Dateiformate signierbar: PDF, XML, JPG, DOC, XLS, PPT, MP3, MP4 etc.</li>
								<li>Ihre zu signierenden Dokumente bleiben bei Ihnen und werden nicht an sign-me übertragen</li>
								<li>Auch Stapelsignatur möglich</li>
								<li>PDF-Signaturstempel beliebig gestaltbar und positionierbar</li>
								<li>Professionelle Nutzung von PDF-Signaturfeldern und Integration in Workflows</li>
							</ul>
						</p>
					</div>
				</div>
				<div className="white">
					<div className="container center">
						<div className="spacer" />
						<div>
							<div>Um Dokumente qualifiziert elektronisch per <strong>sign-me</strong>-Fernsignatur unterschreiben zu können,</div>
							<div>müssen Sie sich bei sign-me registrieren und identifizieren.</div>
						</div>
						<div className="spacer" />
						<div className="popupcontainer">
							<div className={this.state.pointKeyInfoVisible ? "popup" : "invisible"}>
								<label onClick={this.pointKeyInfoVisibilityBind(false)} className="closebox"></label>
								<div className="card">
									<label className="info" />
									<div className="spacer" />
									<p>
										Voraussetzung für die Nutzung von sign-me in Kombination mit <DigiSeal />office ist
										ein <DigiSeal /> Punktekontingent mit einem entsprechenden Punkte-Zugangsschlüssel.
									</p>
									<p>
										Die Signatursoftware <DigiSeal />office sowie <DigiSeal /> Punkte
										können Sie direkt in unserem <a className="arrow" href='https://shop.secrypt.de/' target='_blank'>Online-Shop</a> erwerben.
									</p>
									<p>
										<a className='arrow' href='https://www.secrypt.de/loesungen/fernsignatur-handy-signatur/' target='_blank'>Wie funktionieren <DigiSeal /> Punkte?</a>
									</p>
								</div>
							</div>
						</div>
						
						<div className="narrow">
							<div className={this.state.commonError ? "textinput error" : "textinput"}>
								<label onClick={this.pointKeyInfoVisibilityBind(true)} className="info"><DigiSeal /> Punkte-Zugangsschlüssel</label>
								<div onClick={this.onPointKeyVisiblity} className={this.state.pointKeyVisible ? "eye dash" : "eye"} />
								<input
									type={this.state.pointKeyVisible ? "text" : "password"}
									value={this.state.pointKey}
									onChange={this.handlePointKeyChange}
									onKeyDown={this.handleKeyDownBind()}
									autoFocus="autofocus"
								/>
							</div>
						</div>
						<div className="spacer"></div>

						{this.state.commonError &&
							<div className="narrow">
								<ul className="narrow errorList">
									<li>Der <DigiSeal /> Punkte-Zugangsschlüssel konnte nicht überprüft werden.</li>
									<li>{this.state.commonError}</li>
									{this.state.commonErrorDetails && <li className="small">{this.state.commonErrorDetails}</li>}
								</ul>
							</div>
						}

						<div className="cardcontainer">
							<div className="narrow">
								<div className="card">
									<p>Jetzt für sign-me registrieren</p>
									<img src="images/icons/login_grey.png" alt="Registrieren"/>
									<div className="button yellow">
										<button onClick={this.handleSubmitBind('create')}>Registrieren</button>
									</div>
								</div>
							</div>
							<div className="narrow">
								<div className="card">
									<p>
										Bereits registriert<br/>
										bei sign-me?
									</p>
									<img src="images/icons/handwithcard_grey.png" alt="Identifizieren"/>
									<div className="button">
										<button onClick={this.handleSubmitBind('identify')}>Identifizieren</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="spacer"></div>
					<div className="container">
						<p><a className="arrow light" href="https://www.secrypt.de/produkte/support/#signmefaqs" target='_blank'>FAQs (Häufige Fragen und Antworten)</a></p>
					</div>
					<div className="container">
						<p className='arrow light'>
							<Schnellanleitung dsOffice>
								Anleitung: Signatursoftware digiSeal office (pro) mit sign-me Fernsignatur einrichten (PDF)
							</Schnellanleitung>
						</p>
						<p className='arrow light'>
							<Schnellanleitung>
								Anleitung: Registrieren und Identifizieren für sign-me Fernsignatur (PDF)
							</Schnellanleitung>
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default LandingForm