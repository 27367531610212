import React, { Component } from 'react';

// Import custom components
import Title from './title.component';
import Content from './content.component.js';
import {IndexLink} from 'react-router';

class Dashboard extends Component {

	constructor(props) {
		super(props)
	}

    componentDidMount(){
        $('.treeview').find('a').trigger('click');
    }

    render(){
        return (
            <div>
                <Title />
                
                <Content type={this.props.params.type} />

          
            </div>
        );
    }
}

export default Dashboard