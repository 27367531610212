import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import _ from 'lodash';
import Common from '../../constants/common';

import * as apiAction from '../../actions/apiAction';
import * as crudAction from '../../actions/crudAction';
import * as flashMessage  from '../../actions/flashMessage';

import DataTable from '../common/table/table.component';
import axios from "axios";
import DigiSeal from '../common/digiSeal.component.jsx';
import AppConstant from "../../constants/app";

class TransactionList extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.props.actions.clearList(Common.TRANSACTIONACOUNT);
        this.props.actions.removeFlashMessage();
        this.props.actions.apiClearState();
        this.props.actions.clearSelectedItem(Common.TRANSACTIONACOUNT);
    }

    fetchData() {
        this.props.actions.fetchAll(Common.TRANSACTIONACOUNT);
    }

    componentDidMount() {
        document.title = "Punkte-Zugangsschlüssel – digiSeal Portal by secrypt GmbH"

        $('input[name="transactionName"]').unbind();
        $('.add-transaction-code-btn').off('click').on('click', function(){

            var transactionName = $('input[name="transactionName"]').val();
            if (!transactionName) {
                $('input[name="transactionName"]').next('.input-info-output').addClass('error').html('Bitte bezeichnen Sie den Schlüssel.');
                return false;
            }

            var data = {};
            data.transactionName = transactionName;
            axios.post(AppConstant.API_URL + 'transactionaccount', {data})
                .then(res => {
                    if (res.data.status == 'error') {
                        $.toast({
                            heading: 'Fehler beim Erzeugen des Zugangsschlüssel',
                            text: 'Der Zugangsschlüssel konnte nich erzeugt werden. Bitte prüfen Sie Ihre Eingabe.',
                            showHideTransition: 'slide',
                            position: 'top-right',
                            icon: 'error',
                            allowToastClose: true,
                            hideAfter: 10000,
                            bgColor: '#c01818'
                        })
                    } else {
                        $.toast({
                            heading: 'Zugangsschlüssel erzeugt',
                            text: 'Der Zugangsschlüssel wurde erfolgreich gespeichert.',
                            showHideTransition: 'slide',
                            position: 'top-right',
                            icon: 'success',
                            allowToastClose: true,
                            hideAfter: 1000,
                            bgColor: '#6cbf00',
                            beforeHide: function () {
                                window.location.reload(true);
                            }
                        })
                    }
                });
        })

        $('.transaction-add-container').off('click').on('click', '.edit-transaction-btn', function(){

            var setId = parseInt($(this).data('id'));

            axios.get(AppConstant.API_URL + 'transactionaccount/' + setId, {setId})
                .then(res => {
                    var activeChecked = res.data.data.userActivationStatus ? 'checked' : '';
                    $.confirm({
                        title: false,
                        boxWidth: '600px',
                        useBootstrap: false,
						escapeKey: true,
						backgroundDismiss: true,
                        content:
							'<div class="modal-editor transaction-editor-modal">' +
								'<div class="transaction-editor-list">' +
									'<div class="head"><label>ID</label><span>' + res.data.data.id + '</span></div>' +
									'<div class="head"><label>Punkte-Zugangsschlüssel (PAK)</label><span>' + res.data.data.key + '</span></div>' +
									'<div class="head"><label>Zuletzt bearbeitet</label><span>' + res.data.data.lastModification + '</span></div>' +

									'<div class="descr"><label>Bezeichnung</label></div>' +
									'<div class="span2"><input type="text" name="transactionName" class="transactionName form-control" value="' + res.data.data.transactionName + '" /></div>' +

									'<div class="descr"><label>Status</label></div>' +
									'<div class="span2">' +
										'<div class="slider">' +
											'<input id="' + setId + '" type="checkbox" ' + activeChecked + ' value="1" class="userActivationStatus" />' +
											'<div class="off">inaktiv</div>' +
											'<label for="' + setId + '">' +
												'<span class="round"></span>' +
											'</label>' +
											'<div class="on">aktiv</div>' +
										'</div>' +
									'</div>' +
								'</div>' +
							'</div>',
                        buttons: {
                            confirm: {
                                text: 'Änderungen übernehmen',
                                btnClass: 'modal-editor-btn btn-primary btn',
                                action: function () {
                                    var data = {};
                                    data.transactionName = $('.transactionName').val();
                                    data.userActivationStatus = $('.userActivationStatus').is(':checked') ? 1 : 0;

                                    if (!data.transactionName) {
                                        $.alert('Bitte geben Sie eine Bezeichnung ein.');
                                        return false;
                                    }

                                    axios.post(AppConstant.API_URL + 'transactionaccount/' + setId, {data})
                                        .then(res => {
                                            if (res.data.status != undefined && res.data.status == 'ok') {
                                                $.toast({
                                                    heading: 'Änderungen gespeichert',
                                                    text: 'Ihre Änderungen wurden erfolgreich gespeichert.',
                                                    showHideTransition: 'slide',
                                                    position: 'top-right',
                                                    icon: 'success',
                                                    allowToastClose: true,
                                                    hideAfter: 1000,
                                                    bgColor: '#6cbf00',
                                                    beforeHide: function () {
                                                        window.location.reload(true);
                                                    }
                                                });
                                            }
                                        });
                                }
                            },
                            cancel: {
                                btnClass: 'cancel-btn',
                                text: 'Abbrechen',
                                action: function () {
                                }
                            },
                        }
                    });

                });
            return false;
        })
    }

    handleChange(event) {
        var key = event.target.name;
        var value = event.target.value;
        this.props.actions.updateSelectedItem(Common.CONTINGENT, key, value);
    }

    render() {

        let data = this.props.transactionaccounts;

        return (
            <div className="col-12 transaction-add-container">
                <p>
                    Erzeugen Sie bei Bedarf weitere Punkte-Zugangsschlüssel („PAK-...“: Point Access Key) zu Ihrem <DigiSeal /> Punkte-Kontingent.
                    Anwendung: Den Zugangsschlüssel hinterlegen Sie in der Signatursoftware <DigiSeal />office, um mit sign-me signieren zu können.
                    Ebenfalls benötigen Sie den Zugangsschlüssel für das „Registrieren und identifizieren für sign-me“.
                </p>

                <div className="col-md-12 no-padding padding-top-20px margin-bottom-20px">
                    <div className="form-horizontal">
                        <label className="col-md-10 control-label"><img src="./images/icons/dbkey.png" alt="" className="ticket-icon" />Neuen Zugangsschlüssel erzeugen:</label>
                        <div className="col-md-8 no-padding">
                            <div className="data-container">
                                <input type="text" name="transactionName" className="form-control" placeholder="Bitte bezeichnen Sie den Schlüssel" />
                                <span className="input-info-output"></span>
                            </div>
                        </div>
                        <div className="col-xs-4 button">
                            <button type="submit" className="btn btn-primary add-transaction-code-btn">Schlüssel erzeugen</button>
                        </div>
                    </div>
                </div>

                <div className="points-keys-data-container">
                    <DataTable columns={columns} data={data} options={options} loadingText="Ihre Punkte-Zugangsschlüssel werden geladen..." />
                </div>
            </div>
        );
    }
}

TransactionList.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

/**
 * Map the state to props.
 */
function mapStateToProps(state) {

    return {
        transactionaccounts: state.crud.transactionaccounts,
        apiState: state.api,
        message: state.flash.message
    };

}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(_.assign({}, crudAction, apiAction, flashMessage), dispatch)
    };
}

const validateTransaction = values => {
    const errors = {};

    if (!values.transactionName) {
        errors.code = '(Bitte geben Sie eine Bezeichnung ein.)'
    }
    return errors;
};

const columns = [
    {displayName: 'Zuletzt bearbeitet', attribute: 'lastModification'},
    {displayName: 'Punkte-Zugangsschlüssel (PAK)', attribute: 'key'},
    {displayName: 'Bezeichnung', attribute: 'transactionName'},
    {displayName: 'Status', attribute: 'userActivationStatus'},
    {displayName: '', attribute: '', align: 'center'}
];

const options = {model: 'transactionaccounts'};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'TransactionForm', // ←A Unique identifier for this form
    validate: validateTransaction,  // ←Callback function for client-side validation
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(TransactionList))
