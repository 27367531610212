import axios from "axios";
import AppConstant from "../../constants/app";

function getMonth (year, month, type, then)
{
	var data = {
		type: type == 'day' ? 'DAY' : 'USER',
		year: year,
		month: month,
	};
	axios.post(AppConstant.API_URL + 'statistics-get', {data})
	.then(res => {
		if (res.data.code == "ok") {
			var days = res.data.data;
			then (days);
		}
	});
}

function getTotal (token, then)
{
	var data = {
		type: 'TOTAL',
	};

	// load stats data
	axios.post(AppConstant.API_URL + 'statistics-get', {data})
		.then(res => {
			if (res.data.code == "ok") {
				var statistics = res.data.data;
				then (statistics);
			}
		});
}

export default {
	getTotal: getTotal,
	getMonth: getMonth,
};
