 
import React, { Component } from 'react';

// Import custom components
import DigiSeal from '../common/digiSeal.component.jsx';

class SignMeFollowupForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			state: "unknown",
		};
	}

	componentDidMount() {
		var search = window.location.search.substring(1); // ok, fail, cancel
		var text = (search == "ok" || search == "fail" || search == "cancel") ? search : "unknown";
		this.setState({state:text});

		switch (text) {
			case "ok":     document.title = "Identifizierung erfolgreich - secrypt GmbH"; break;
			case "fail":   document.title = "Problem bei der Identifizierung - secrypt GmbH"; break;
			case "cancel": document.title = "Identifizierung abgebrochen - secrypt GmbH"; break;
			default:       document.title = "Technischer Fehler - secrypt GmbH"; break;

		}
	}

	backToStartBind(arg)
	{
		return () => {
			const t = this.props.params.signerToken;
			window.location.hash = (t && (arg != 'done'))
				? ("signerToken/signme-regid/" + t) : "signme-regid";
			window.scroll(0,0);
		}
	}

	enterEmail()
	{
		window.location.hash = "enteremail";
		window.scroll(0,0);
	}

	render() {
		return (
			<div className="content">
				<form method="post" onSubmit={this.handleSubmitBind}>
					<div className="white">
						<div className="container">
							{{
								ok:
									<div>
										<h1>Identifizierung erfolgreich</h1>
										{this.props.params.signerToken ?
											<p>
												Sie können jetzt Ihr sign-me Account benutzen, um mit <DigiSeal />web Dokumente
												qualifiziert elektronisch zu signieren.
												<br/>
												Sie können dieses Fenster jetzt schließen.
											</p>
											:
											<p>
												Sie können jetzt Ihr sign-me Account benutzen, um mit <DigiSeal />office Dokumente
												qualifiziert elektronisch zu signieren.
												Wie dazu <DigiSeal />office einzurichten ist, erfahren Sie
												in den <a href="https://www.secrypt.de/produkte/support/#signmefaqs" target='_blank'>FAQs (Häufige
												Fragen und Antworten)</a>.
											</p>
										}
										<div className="spacer"></div>
										{!this.props.params.signerToken &&
											<div className="buttoncontainer">
												<div className="remainder">
												</div>
												<div className="button fixed yellow">
													<button type="button" onClick={this.backToStartBind('done')}>Zur Startseite</button>
												</div>
											</div>
										}
									</div>,
								fail:
									<div>
										<h1>Problem bei der Identifizierung</h1>
										<p>
											Die Identifizierung konnte nicht durchgeführt werden.
											Der Vorgang wurde von unserem Partner D-Trust oder Identity TM abgebrochen.
										</p>
										<p>
											Das kann unterschiedliche Gründe haben, z.B.: <br />
											- Technischer Fehler <br />
											- Fehlerhafte Identifizierung, z.B. Aufnahme falscher Ausweisdaten <br />
											- Wartungsarbeiten <br />
										</p>
										<p>
											Für einen weiteren Versuch klicken Sie bitte den 'Erneut Identifizieren'-Button.											</p>
										<p>
											Falls das Problem weiterhin auftritt, 
											wenden Sie sich bitte direkt an den <a href="https://www.d-trust.net/kontakt.html" target='_blank'>Support unseres Partners D-Trust</a>.
										</p>
										<div className="spacer"></div>
										<div className="buttoncontainer">
											<div className="button fixed">
												<button type="button" onClick={this.backToStartBind()}>Abbrechen</button>
											</div>
											<div className="remainder">
											</div>
											<div className="button fixed yellow">
												<button type="button" onClick={this.enterEmail}>Erneut Identifizieren</button>
											</div>
										</div>
									</div>,
								cancel:
									<div>
										<h1>Identifizierung abgebrochen</h1>
										<p>
											Für einen weiteren Versuch klicken Sie bitte den 'Erneut Identifizieren'-Button.
										</p>
										<div className="spacer"></div>
										<div className="buttoncontainer">
											<div className="button fixed">
												<button type="button" onClick={this.backToStartBind()}>Zur Startseite</button>
											</div>
											<div className="remainder">
											</div>
											<div className="button fixed yellow">
												<button type="button" onClick={this.enterEmail}>Erneut Identifizieren</button>
											</div>
										</div>
									</div>,
							}[this.state.state] ||
									<div>
										<h1>Technischer Fehler</h1>
										<p>Diese URL ist nicht gültig.</p>
										<p>Gehen Sie zurück zur Startseite und wiederholen Sie den Vorgang.</p>
										<div className="spacer"></div>
										<div className="buttoncontainer">
											<div className="remainder">
											</div>
											<div className="button fixed yellow">
												<button type="button" onClick={this.backToStartBind()}>Zur Startseite</button>
											</div>
										</div>
									</div>
							}
							<div className="spacer"></div>

							{/*
							<div className="itemcontainer">
								<div className="button fixed">
									<button type="button" onClick={this.backToStartBind()}>Abbrechen</button>
								</div>
								<div className="remainder">
								</div>
								<div className="button fixed yellow">
									<button type="submit" disabled={this.state.submitting}>Weiter</button>
								</div>
							</div> */}
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default SignMeFollowupForm
