import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import AppConstant from '../../constants/app';
import cookie from 'react-cookie';
import axios from 'axios';
import _ from 'lodash';
import * as authService from '../../services/authService';
import DigiSeal from '../common/digiSeal.component.jsx';

import renderText from '../resetpw/renderText';
import * as flashMessage from "../../actions/flashMessage";

class PwResetActionForm extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        // check for cookie set and forward user to dashboard if already logged in
        if (cookie.load(AppConstant.TOKEN)) {
            window.location.href = AppConstant.DASHBOARD_URL;
        }

    }

    initPwReset(password, token) {
        axios.post(AppConstant.API_URL + 'account-pwreset-action', {password, token})
            .then(res => {
                let message = 'Es ist ein Fehler aufgetreten und Ihr Passwort wurde nicht geändert. Bitte versuchen Sie es erneut.';
                if (res.data.code == "ok") {
                    message = 'Ihr Passwort wurde erfolgreich geändert. Sie können sich mit dem neuen Passwort und Ihrem Benutzernamen nun anmelden.';
                }
                bootbox.alert({
                    message: message,
                    callback: function () {
                        window.location.href = AppConstant.ROOT_URL;
                    }
                });
            });
    }

    componentWillMount() {
        window.$('body').addClass('hold-transition login-page');
    }

    componentWillUnmount() {
        this.props.actions.removeFlashMessage();
    }

	componentDidMount() {
		const p1 = document.getElementsByName("newpassword");
        const p2 = document.getElementsByName("newpasswordrepeat");

		const t1 = document.querySelector('#togglePassword1');
		const t2 = document.querySelector('#togglePassword2');

		t1.addEventListener('click', function (e) {
			const type = p1[0].getAttribute('type') === 'password' ? 'text' : 'password';
			p1[0].setAttribute('type', type);
			this.classList.toggle('dash');
		});
		t2.addEventListener('click', function (e) {
			const type = p2[0].getAttribute('type') === 'password' ? 'text' : 'password';
			p2[0].setAttribute('type', type);
			this.classList.toggle('dash');
		});
	}

    handleSubmit() {

        let password = $('input[name="newpassword"]');
        let passwordRepeat = $('input[name="newpasswordrepeat"]');
        let token = this.props.params.id;

        $('body').find('.help-block').html('');
        $('body').find('.error').removeClass('error');

        if (password.val() == '') {
            password.addClass('error').parent().find('.help-block').addClass('error').html('Geben Sie bitte ein Passwort ein.');
        }
        if (passwordRepeat.val() == '') {
            passwordRepeat.addClass('error').parent().find('.help-block').addClass('error').html('Geben Sie bitte Ihr Passwort nochmals ein.');
        }

        if (passwordRepeat.val() != '' && (password.val() !== passwordRepeat.val())) {
            passwordRepeat.addClass('error').parent().find('.help-block').addClass('error').html('Die Passwörter stimmen nicht überein.');
        }

        if ($('.error').length == 0) {
            this.initPwReset(passwordRepeat.val(), token);
        }
        return false;
    }

	render() {

		let message = this.props.message;
		const {handleSubmit, submitting} = this.props;

		return (
			<div className="content">
				<div className="login-box">
					<div className="login-box-body">
						<h3><DigiSeal /> Konto</h3>
						<h3>Neues Passwort vergeben</h3>
						<p className="login-box-msg">
							Vergeben Sie hier ein neues Passwort für Ihr <digiSeal /> Konto.
						</p>

						<form method="post" onSubmit={handleSubmit(this.handleSubmit)}>
							<Field
								name="newpassword"
								component={renderText}
								type="password"
								label="Ihr neues Passwort"
							>
								<i className="eye togglePassword" id="togglePassword1"></i>
							</Field>
							<Field
								name="newpasswordrepeat"
								component={renderText}
								type="password"
								label="Ihr neues Passwort wiederholen"
							>
								<i className="eye togglePassword" id="togglePassword2"></i>
							</Field>

							<div id="g-recaptcha"></div>
							<div className="help-block"></div>
							<div className="row">
								<div className="col-xs-8">
									<a href="#/" className="arrow">Zurück zur Anmeldung</a>
								</div>
								<div className="col-xs-4 button">
									<button type="submit" className="btn btn-primary btn-block btn-flat right"
											disabled={submitting}>Absenden
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

PwResetActionForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

/**
 * Map the state to props.
 */
function mapStateToProps(state) {
    return {
        token: state.auth.token,
        isAuthenticated: state.auth.isAuthenticated,
        message: state.flash.message
    }
}

/**
 * Map the actions to props.
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(_.assign({}, authService, flashMessage), dispatch)
    }
}


/**
 * Connect the component to the Redux store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'PwResetActionForm', // ←A Unique identifier for this form
})(PwResetActionForm))
