import 'babel-polyfill';
import React, { Component } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

// Import routing components
import {hashHistory, Router, Route, IndexRoute} from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import RequireAuth from './components/auth/authenticate.component';

// wrapper components
import AppPlain from './components/app.plain.component';
import AppSignerToken from './components/app.signertoken.component';
import AppAuth from './components/app.auth.component';

// Import custom components

import WizardLandingForm               from './components/wizard/1.landing.component.jsx';
import WizardSignerTokenRedirectPage   from './components/wizard/1.signertokenredirect.component.jsx';
import WizardCreateSignMeAccountForm   from './components/wizard/2.createsignmeaccount.component.jsx';
import WizardVerifyEmailForm           from './components/wizard/3.verifyemail.component.jsx';
import WizardEnterEmailForm            from './components/wizard/3.enteremail.component.jsx';
import WizardStartSignMeIdentifyForm   from './components/wizard/4.startsignmeidentify.component.jsx';
import WizardSignMeFollowupForm        from './components/wizard/6.signmefollowup.component.jsx';

import ImpressumForm from './components/common/impressum.component.jsx';
import DatenschutzerklaerungForm from './components/common/datenschutzerklärung.component.jsx';

import NotFoundPage from './components/error/not-found.component';
import LoginForm from './components/login/login.component';
import LoginActivateForm from './components/login/loginactivate.component';
import PwResetForm from './components/resetpw/resetpw.component';
import PwResetActionForm from './components/resetpw/resetpwaction.component';
import CreateAccountForm from './components/create/createadmin.component';
import Dashboard from './components/dashboard/dashboard.component';
import EmailnoticesConfirm from './components/emailnotices/emailnotices-notice-confirm.component';

import Transaction from './components/transaction/transaction.component';
import TransactionList from './components/transaction/transaction-list.component';

import Contingent from './components/contingent/contingent.component';
import ContingentList from './components/contingent/contingent-list.component';

import Statsandusage from './components/statsandusage/statsandusage.component';
import StatsandusageList from './components/statsandusage/statsandusage-list.component';

import Emailnotices from './components/emailnotices/emailnotices.component';
import EmailnoticesList from './components/emailnotices/emailnotices-list.component';

import Account from './components/account/account.component';
import AccountList from './components/account/account-list.component';

import store from './store/store';

const history = syncHistoryWithStore(hashHistory, store);

render(
	<Provider store={store}>
		<Router onUpdate={() => window.scrollTo(0, 0)} history={history}>

			<Route path="/" component={AppPlain}>
				<IndexRoute component={LoginForm} />
				<Route path="login" component={LoginForm}/>

				<Route path="signme-regid"          component={WizardLandingForm}/>
				<Route path="createsignmeaccount"   component={WizardCreateSignMeAccountForm}/>
				<Route path="enteremail"            component={WizardEnterEmailForm}/>
				<Route path="verifyemail"           component={WizardVerifyEmailForm}/>
				<Route path="startsignmeidentify"   component={WizardStartSignMeIdentifyForm}/>
				<Route path="signmefollowup"        component={WizardSignMeFollowupForm}/>
				<Route path="followupUrl"           component={WizardSignMeFollowupForm}/>

				<Route path="datenschutzerklaerung" component={DatenschutzerklaerungForm}/>
				<Route path="impressum"             component={ImpressumForm}/>
				<Route path="confirmemail/:id/"     component={EmailnoticesConfirm}/>
				<Route path="create"                component={CreateAccountForm}/>
				<Route path="activate/:id/:uid"     component={LoginActivateForm}/>
				<Route path="resetpw"               component={PwResetForm}/>
				<Route path="resetpw/:id/"          component={PwResetActionForm}/>
			</Route>

			<Route path="/signerToken/" component={AppSignerToken}>
				<Route path="signme-regid/:signerToken"        component={WizardSignerTokenRedirectPage}/>
				<Route path="createsignmeaccount/:signerToken" component={WizardCreateSignMeAccountForm}/>
				<Route path="verifyemail/:signerToken"         component={WizardVerifyEmailForm}/>
				<Route path="startsignmeidentify/:signerToken" component={WizardStartSignMeIdentifyForm}/>
				<Route path="signmefollowup/:signerToken"      component={WizardSignMeFollowupForm}/>
				<Route path="followupUrl/:signerToken"         component={WizardSignMeFollowupForm}/>
			</Route>

			<Route path="/dashboard" component={AppAuth}>
				<IndexRoute component={RequireAuth(Dashboard)} />
				<Route path=":type" component={(Dashboard)}/>

				<Route path="/point-access-key" component={RequireAuth(Transaction)}>
					<IndexRoute component={TransactionList}/>
				</Route>

				<Route path="/digiseal-points" component={RequireAuth(Contingent)}>
					<IndexRoute component={ContingentList}/>
				</Route>

				<Route path="/stats-and-usage" component={RequireAuth(Statsandusage)}>
					<IndexRoute component={StatsandusageList}/>
				</Route>

				<Route path="/email-notices" component={RequireAuth(Emailnotices)}>
					<IndexRoute component={EmailnoticesList}/>
				</Route>

				<Route path="/account" component={RequireAuth(Account)}>
					<IndexRoute component={AccountList}/>
				</Route>
			</Route>
			
			<Route path="*" component={NotFoundPage} />
		</Router>
	</Provider>,
	document.getElementById('root-container')
);