 
import React, { Component } from 'react';
import AppConstant from '../../constants/app';
import axios from 'axios';

// Import custom components
import AxiosResultHandler from '../common/AxiosResultHandler.component';

class StartSignMeIdentifyForm extends Component {

	constructor(props) {
		super(props);

		this.openPopupBind = this.openPopup.bind(this);

		this.state = {
			commonError: null,
			commonErrorDetails: null,
			processState: null,
			button: {
				url: null,
				text: null,
			},
		};
	}

	componentDidMount() {
		document.title = "Identifizierung für sign-me - secrypt GmbH"

		this.getSignerStatus();
	}

	openPopup() {
		var url = this.state.button.url;
		var x = window.screenX;
		var y = window.screenY;
		var w = window.innerWidth;
		var h = window.innerHeight;
		if (w > 600) {
			w -= 200;
			x += 100;
		}
		window.open(url, '_blank', 'left='+x+',top='+y+',width='+w+',height='+h+',menubar,toolbar,location,status,resizable,scrollbars,noopener,noreferrer');

		if (this.state.processState == 'pending'
		 || this.state.processState == 'new')
		{
			this.setState({
				button: {url:url, text:'sign-me Webseite öffnen'},
				processState: 'active',
			});
		}
	}

	getSignerStatus() {
		
		// reset previous errors
		this.setState({
			commonError: null,
			commonErrorDetails: null,
			processState: null,
			button: {
				url: null,
				text: null,
			},
		});

		var data = {
		};
		const t = this.props.params.signerToken;
		if (t)
			data['signerToken'] = t;

		axios({
			method: 'post',
			url: AppConstant.API_URL + 'get-signer-status',
			data: data,
			timeout: 20000, // ms - since this goes to sign-me, the timeout should be a bit longer than with the landing page.
			                // and since it is a costly operation, we play safe and wait a bit longer still.
			validateStatus: status => { return true; },
		})
		.then(res => {
			console.log(res);

			if (res.status == 200 && res.data && res.data.status == "ok")
			{
				// we have a result.

				if (res.data.data && res.data.data.signerStatus) {
					var ss = res.data.data.signerStatus;
					console.log('ok');
					console.log(ss);

					if (ss.signerExisting !== true) { // this should not happen, unless multithreading issue
						this.setState({
							commonError:"Der Benutzername ist nicht bekannt.",
							commonErrorDetails:"Antwort vom sign-me-Server: 'signerExisting = false'.",
						});
						return;
					}

					if (ss.signerEmailAddressNeedsConfirmation !== false) { // this should not happen, unless multithreading issue
						this.setState({
							commonError:"Sie haben Ihre sign-me E-Mail-Adresse noch nicht bestätigt.",
							commonErrorDetails:"Bitte schauen Sie in Ihr E-Mail-Postfach, ob Sie eine E-Mail von D-Trust zur Verifikation Ihres sign-me Accounts erhalten haben. Klicken Sie auf den Link in dieser E-Mail.",
						});
						return;
					}

					if (ss.signerPasswordNeedsReset !== false) {
						this.setState({
							commonError:"Ihr Passwort ist nicht mehr gültig. Bitte erneuern Sie Ihr Passwort auf https://www.sign-me.de/",
							commonErrorDetails:"Es gab 5 fehlerhafte Anmeldeversuchen. Ihr sign-me Account wurde deshalb gesperrt.",
							button: { url: 'https://www.sign-me.de/', text: 'sign-me Webseite öffnen'},
						});
						return;
					}

					if (ss.signerPasswordNeedsChangeOfInitPassword !== false) { // this should not happen, unless multithreading issue
						this.setState({
							commonError:"Sie haben Ihr sign-me Passwort noch nicht festgelegt. Bitte erstellen Sie Ihr Passwort auf https://www.sign-me.de/",
							button: { url: 'https://www.sign-me.de/', text: 'sign-me Webseite öffnen'},
						});
						return;
					}

					if (ss.activeIdentityVerficationProcessURL !== null) {
						// ok... we still have a valid validation process! Use that!
						this.setState({
							processState: 'pending',
							button: { url: ss.activeIdentityVerficationProcessURL, text: 'Identifizierung fortsetzen'},
						});
						return;
					}

					if (ss.signerNeedsIdentificationAndTokenCreation === true) {
						// ok, sign-me says we need to create a validation process.
						this.setState({
							processState: 'new',
						});
						this.createValidationProcess();
						return;
					}

					// apparently all is already correctly set-up, so done!
					// in the token situation, we simply kick the person to the starting page.
					const t = this.props.params.signerToken;
					if (t)
						window.location.hash = "signerToken/signme-regid/" + t;
				}

				this.setState({commonError:"Technischer Fehler: Die Antwort vom Server ist ungültig."});
				return;
			}

			var ret = AxiosResultHandler.ok(res);
			this.setState(ret);
		})
		.catch(error => {
			var ret = AxiosResultHandler.error(error);
			this.setState(ret);
		});

		return;
	}

	createValidationProcess() {
		var location = document.createElement('a'); // wonderful world of javascript
		location.href = window.location.href;

		const t = this.props.params.signerToken;
		location.hash = t ? ("signerToken/signmefollowup/" + t) : "signmefollowup";

		location.search = "ok";
		var locationOk = location.href;

		location.search = "cancel";
		var locationCancel = location.href;

		location.search = "fail";
		var locationFail = location.href;

		var data = {
			'certificateType': 'QES',
			'followupUrl': {
				'ok':     locationOk,
				'cancel': locationCancel,
				'fail':   locationFail,
			}
		};
		if (t)
			data['signerToken'] = t;

		console.log("posting with ");
		console.log(data);

		axios({
			method: 'post',
			url: AppConstant.API_URL + 'verify-signme-identity',
			data: data,
			timeout: 20000, // ms - since this goes to sign-me, the timeout should be a bit longer than with the landing page.
			                // and since it is a costly operation, we play safe and wait a bit longer still.
			validateStatus: status => { return true; },
		})
		.then(res => {
			if (res.status == 200 && res.data && res.data.status == "ok")
			{
				// // we have a result. See if we have a URL.
				if (!res.data.data || res.data.data.url === undefined || res.data.data.url === null) {
				 	this.setState({commonError:"Die sign-me Antwort enthält keine URL."});
					return;
				}

				// yay! success
				console.log("sign-me process URL: " + res.data.data.url);
				this.setState({
					button: { url: res.data.data.url, text: 'Identifizieren'},
				});
				return;
			}

			var ret = AxiosResultHandler.ok(res);
			this.setState(ret);
		})
		.catch(error => {
			var ret = AxiosResultHandler.error(error);
			this.setState(ret);
		});
	}

	backToStartBind()
	{
		return () => {
			const t = this.props.params.signerToken;
			window.location.hash = t ? ("signerToken/signme-regid/" + t) : "signme-regid";
			window.scroll(0,0);
			return;
		}
	}

	render() {
		return (
			<div className="content">
				<div className="white">
					<div className="container">

						<h1>Identifizierung für sign-me</h1>
						{{
							'new':
								<div>
									<p>
										Sie besitzen ein sign-me Account und können sich nun identifizieren.
										Die Identifizierung ist notwendig, damit Sie mit sign-me qualifizierte Fernsignaturen erzeugen können.
									</p>
									<p><b>Schritte</b>: <br/>
										Bitte lesen Sie sich die folgenden Anweisungen sorgfältig durch.</p>
									<p>
										<ul className="number">
											<li>
												Nach Betätigung des gelben "Identifizieren"-Buttons
												öffnet sich die sign-me Webseite von D-Trust in einem neuen Tab
												oder in einem neuen Popup-Fenster (browserabhängig).
											</li>
											<li>
												Loggen Sie sich auf der sign-me Webseite von D-Trust 
												mit Ihrer E-Mail-Adresse (sign-me Benutzername) und Passwort ein.
											</li>
											<li>
												Sie gelangen auf die "Identifizieren"-Seite der sign-me Webseite von D-Trust.
												Bitte lesen Sie dort die Anweisungen aufmerksam durch.
												Nach Klicken des "Weiter"-Buttons gelangen Sie zur Identifizierung
												zum D-Trust-Partner <strong>Identity TM</strong>.
											</li>
											<li>
												Sie werden zur Identity TM-Webseite weitergeleitet.
												Hier können Sie sich auf verschiedene Arten identifizieren, z.B.: <br/>
												<b>a) Video-Ident mit Browser</b><br/>
												Hierfür benötigen Sie: <br/>
												– Ein Notebook mit Mikrofon und Kamera oder ein Smartphone <br/>
												– Ihren Personalausweis oder ein anderes Ausweisdokument, z.B. Reisepass <br/>
												Nach dem Identifizierungsvorgang werden Sie auf die secrypt-Webseite 
												geleitet und das Ergebnis der Identifizierung wird Ihnen angezeigt. <br/>

												<b>b) Video-Ident mit identity App für Smartphones</b><br/>
												Hierfür benötigen Sie: <br/>
												– Ein aktuelles Smartphone mit Android oder iOS (iPhone) <br/>
												– Ihren Personalausweis oder ein anderes Ausweisdokument, z.B. Reisepass <br/>
												Nach dem Identifizierungsvorgang schließen Sie bitte die identity TM-Webseite.
												Um die Identifizierung abzuschließen,
												klicken Sie auf der secrypt-Webseite den Button "sign-me Webseite öffnen"
												und loggen sich dort ein. Bitte bestätigen Sie anschließend die Bedingungen
												auf der Seite "Signaturzertifikate erzeugen". <br/>

												<b>c) Online-Ausweisfunktion des Personalausweises (eID) + AusweisApp2</b><br/>
													Hierfür benötigen Sie: <br/>
												– Ein Notebook und ein Kartenlesegerät oder ein NFC-fähiges Smartphone <br/>
												– Die AusweisApp2 – sie muss installiert und auf dem Notebook auch gestartet sein <br/>
												– Eine freigeschaltete Online-Ausweisfunktion Ihres Personalausweises und Ihre PIN <br/>
												Nach dem Identifizierungsvorgang werden Sie auf die secrypt-Webseite 
												geleitet und das Ergebnis der Identifizierung wird Ihnen angezeigt. <br/>

											</li>
										</ul>
									</p>
									<p>
										Um die Identifizierung zu starten, klicken Sie bitte auf den gelben "Identifizieren"-Button.
									</p>
								</div>,
							'pending':
								<div>
									<p>
										Mit diesem sign-me Benutzernamen wurde bereits eine Identifizierung gestartet,
										die noch nicht abgeschlossen ist. <br/>
										Bitte klicken Sie auf den Button "Identifizierung fortsetzen".
									</p>
								</div>,
							'active':
								<div>
									<p>
										Sie haben die Identifizierung gestartet.
									</p>
									<p>
										Es hat sich die sign-me Webseite von D-Trust in einem neuen Tab
										oder in einem neuen Popup-Fenster (browserabhängig) geöffnet.
									</p>
									<p>
										<b>Achtung Popup-Blocker</b>: <br/>
										Ein aktiver Popup-Blocker könnte das Öffnen des Fensters verhindern.
										Bitte beachten Sie die Anweisungen Ihres Browsers.
									</p>
									<p>
										<b>Sie haben das Fenster versehentlich geschlossen</b>: <br/>
										Bitte klicken Sie den Button "sign-me Webseite öffnen", um das Fenster erneut zu öffnen.
									</p>
									<p>
										<b>Video-Ident mit identity App für Smartphones</b>: <br/>
										Nach dem Identifizierungsvorgang mit der identity App schließen Sie
										bitte die identity TM-Webseite. Um die Identifizierung abzuschließen,
										klicken Sie auf den Button "sign-me Webseite öffnen"
										und loggen sich dort ein. Bitte bestätigen Sie anschließend die Bedingungen
										auf der Seite "Signaturzertifikate erzeugen".
									</p>
								</div>,
						}[this.state.processState] ||
								<div>
									<p>
									</p>
								</div>
						}
						{this.state.commonError &&
							<div>
								<div className="spacer"></div>
								<ul className="errorList">
									<li>Es ist nicht möglich, die Identifizierung zu starten.</li>
									<li>{this.state.commonError}</li>
									{this.state.commonErrorDetails && <li className="small">{this.state.commonErrorDetails}</li>}
								</ul>
							</div>
						}

						<div className="spacer"></div>
						<div className="buttoncontainer">
							<div className="button fixed">
								<button type="button" onClick={this.backToStartBind()}>Abbrechen</button>
							</div>
							<div className="remainder">
							</div>
							{this.state.button.url && this.state.button.text &&
								<div className="button fixed yellow">
									<button type="button" onClick={this.openPopupBind}>{this.state.button.text}</button>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default StartSignMeIdentifyForm;
