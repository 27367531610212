import React, { Component } from 'react';
import Header from './common/header/header.component.jsx';
import Footer from './common/footer/footer.component.jsx';

class AppPlain extends Component {

	constructor(props) {
		super(props);
	}

	render(){
		return (
			<div className="ds-wrapper">
				<Header type='plain' />
					{this.props.children}
				<Footer />
			</div>
		);
	}
}

export default AppPlain