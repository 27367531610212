 import React, { Component } from 'react';

class DatenschutzerklaerungForm extends Component {

	componentDidMount(){
		document.title = "Datenschutzerklärung – digiSeal Portal by secrypt GmbH";
	}

	render(){
		return (
			<div className="legal content">
				<div className="grey">
					<div className="container">
						<h1>Datenschutzerklärung für das digiSeal Portal / digiSeal Konto der secrypt GmbH</h1>
						<p>Information für Betroffene</p>
						<p>Der Schutz Ihrer Daten ist uns sehr wichtig. Mit dieser Datenschutzerklärung erhalten Sie Informationen zur Verwendung Ihrer Daten, die über das digiSeal Portal / digiSeal Konto der secrypt GmbH erfasst werden.</p>
						<p>Diese Datenschutzerklärung gilt nur für die Internetpräsenz unter der Adresse:</p>
						<p>
							<a href="https://digiseal-portal.secrypt.de/">https://digiseal-portal.secrypt.de</a>
						</p>
					</div>
				</div>

				<div className="white">
					<div className="container">
						<h2>Name und Anschrift der verantwortlichen Stelle</h2>
							<p>
								secrypt GmbH<br />
								Hohenzollerndamm 183<br />
								10713 Berlin<br />
								Deutschland
							</p>
							<p>
								Tel.: +49 30 756 59 78-0<br />
								Fax: +49 30 756 59 78-18<br />
								E-Mail: <a href="mailto:datenschutz@secrypt.de">datenschutz@secrypt.de</a><br />
								Webseite: <a href="https://www.secrypt.de" target='_blank'>www.secrypt.de</a>
							</p>
							<p>Geschäftsführer: Tatami Michalek, Matthias Schlede</p>
					</div>
				</div>

				<div className="grey">
					<div className="container">
						<h2>Einleitung</h2>
						<p>„sign-me“ ist der Fernsignatur-Service der D-Trust GmbH (Ein Unternehmen der Bundesdruckerei). Die secrypt GmbH ist Vertriebspartner der Bundesdruckerei GmbH für sign-me.</p>
						<p>Die sign-me-Registrierungs- und -Identifizierungs-Webseite der secrypt GmbH verlinkt im Verlauf der Prozesse zur Registrierung und Identifizierung direkt auf Webseiten, die im Verantwortungsbereich der D-Trust GmbH liegen. Dort werden die Nutzer zur Zustimmung sign-me-spezifischer Nutzungsbedingungen verpflichtet und es werden umfangreiche personenbezogene Daten erhoben, die zur Erbringung des sign-me Fernsignatur-Services notwendig sind. Beachten Sie die entsprechende Datenschutzerklärung der D-Trust GmbH (Ein Unternehmen der Bundesdruckerei), der Sie im Verlauf des sign-me-Registrierungs- und -Identifizierungs-Prozesses zustimmen müssen.</p>
						<p>Die secrypt GmbH ist Anbieter von Signaturanwendungssoftware zur Nutzung von elektronischen Signaturen, elektronischen Siegeln und elektronischen Zeitstempeln. Sie ist kein qualifizierter Vertrauensdienstanbieter (wie z.B. die D-Trust GmbH), die Herausgeber von elektronischen Signaturen, elektronischen Siegeln und elektronischen Zeitstempeln sind.</p>
						<p>Die Erhebung, Verarbeitung und Nutzung personenbezogener Daten erfolgt zur Erfüllung der vorstehend aufgeführten Tätigkeiten der secrypt GmbH.</p>
					</div>
				</div>

				<div className="white">
					<div className="container">
						<h2>Verarbeitungsrahmen</h2>
						<h3>Kategorien personenbezogener Daten, die verarbeitet werden</h3>
						<p>
							<ul>
								<li>E-Mail</li>
								<li>sign-me Benutzername (E-Mail-Adresse)</li>
								<li>digiSeal-Punkte-Zugangsschlüssel</li>
								<li>IP-Adressen</li>
								<li>Zugriffszeitpunkte</li>
								<li>Browsertypen und Versionen</li>
								<li>Betriebssystem des zugreifenden Systems</li>
								<li>Internetseite des zugreifenden Systems</li>
								<li>Internet-Service-Provider des zugreifenden Systems</li>
								<li>Name, Unternehmen, Adresse (freiwillige Angabe)</li>
							</ul>
						</p>
						<h3>Quelle der personenbezogenen Daten</h3>
						<p>Daten der Betroffenen werden bei der Registrierung über ein Webformular aufgenommen und von der Webseite erfasst und in einem Logfile gespeichert.</p>
						<p>E-Mail-Kontakt: Wenn Sie uns per E-Mail kontaktieren, speichern wir die von Ihnen gemachten Angaben, um Ihre Anfrage beantworten und mögliche Anschlussfragen stellen zu können.</p>
						<h3>Dauer der Speicherung</h3>
						<p>Die Speicherung erfolgt so lange, wie es für die Erbringung des Service notwendig ist.</p>
						<h3>Zwecke der Verarbeitung</h3>
						<p>Die Daten werden für die folgenden Zwecke verarbeitet:</p>
						<p>
							<ul>
								<li>Technische Erbringung des Service</li>
								<li>Abrechnung</li>
								<li>für das Erzeugen von Signaturen mittels sign-me bei der Nutzung von digiSeal-Signatursoftware der secrypt GmbH</li>
								<li>Wirtschaftlichkeits- und Qualitätsprüfung</li>
								<li>für statistische Zwecke (anonymisiert)</li>
								<li>in Einzelfällen zur Fehlerbehebung insbesondere bei Supportanfragen</li>
							</ul>
						</p>
						<h3>Rechtsgrundlage für die Verarbeitung</h3>
						<p>Betroffenenanfragen werden auf Grundlage der Art. 12-23 DSGVO bzw. § 32-37 BDSG verarbeitet.</p>
						<h3>Weitergabe und Auslandsbezug</h3>
						<p>Empfänger der Daten sind interne Stellen, die an der Ausführung der jeweiligen Geschäftsprozesse beteiligt sind, z.B. Buchhaltung, Rechnungswesen, Support, Software-Entwicklung, EDV, Marketing, Vertrieb und allgemeine Verwaltung, sowie unsere Partner D-Trust GmbH und Bundesdruckerei GmbH.</p>
						<p>Datenübermittlungen in Drittstaaten ergeben sich nur im Rahmen der Vertragserfüllung, erforderlicher Kommunikation sowie anderer gesetzlich ausdrücklich vorgesehener Ausnahmen. Im Übrigen erfolgt keine Übermittlung in Drittstaaten; eine solche ist auch nicht geplant.</p>
					</div>
				</div>

				<div className="grey">
					<div className="container">
						<h2>Verwendung von Cookies</h2>
						<p>Die Webseiten der secrypt GmbH verwenden Cookies. Cookies sind Textdateien, welche über einen Internetbrowser auf einem Computersystem abgelegt und gespeichert werden.</p>
						<p>Cookies enthalten eine sogenannte Cookie-ID als eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt und identifiziert werden. Hier werden Cookies verwendet, um die Abrechnung des Service über einen digiSeal-Punkte-Zugangsschlüssel zu ermöglichen.</p>
						<p>Die betroffene Person kann die Setzung von Cookies durch dieser Internetseite jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Ferner können bereits gesetzte Cookies jederzeit über einen Internetbrowser oder andere Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern möglich.</p>
						<p>Deaktiviert die betroffene Person die Setzung von Cookies in dem genutzten Internetbrowser, sind unter Umständen wesentliche Funktionen dieser Internetseite nicht nutzbar, z.B. die Abrechnung des Service über den digiSeal-Punkte-Zugangsschlüssel.</p>
					</div>
				</div>

				<div className="white">
					<div className="container">
						<h2>Betroffenenrechte</h2>
						<p>Sollten Sie Anfragen zur Auskunft über Ihre Daten oder deren Berichtigung, Löschung oder Einschränkung der Verarbeitung haben, richten Sie diese bitte an die obenstehende verantwortliche Stelle.</p>
						<p>Gleiches gilt, falls Sie im Sinne des Art. 21 DSGVO Widerspruch gegen die Verarbeitung einlegen wollen oder eine Anfrage zur Datenübertragbarkeit haben.</p>
						<p>Sollten Sie Fragen oder Beschwerden zu einem Verfahren haben, wenden Sie sich bitte über die oben genannten Kontaktmöglichkeiten an uns.</p>
						<br />
						<p>Juni 2021</p>
					</div>
				</div>

			</div>
		);
	}
}

export default DatenschutzerklaerungForm
