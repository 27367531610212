import React, { Component } from 'react';

import AppConstant from "../../../constants/app";

var HeaderContent= (props) => {

	return (
		<div className={props.fixed ? "header fixed" : "header"}>
			<span className="security-logo">
				<img src="./images/security_logo.jpg"></img>
			</span>
			<div className="container">
				<div className='itemcontainer low ownsize'>
					<div className="site-logo">
						<img src="./images/digiSeal-by-secrypt.svg" alt="secrypt GmbH" />
					</div>
					<div className="remainder"></div>
				</div>
			</div>
		</div>
	);
}

export default HeaderContent;