import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import AppConstant from '../../constants/app';
import cookie from 'react-cookie';
import axios from 'axios';
import _ from 'lodash';
import * as authService from '../../services/authService';
import DigiSeal from '../common/digiSeal.component.jsx';

import renderText from '../resetpw/renderText';
import * as flashMessage from "../../actions/flashMessage";

class PwResetForm extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        // check for cookie set and forward user to dashboard if already logged in
        if (cookie.load(AppConstant.TOKEN)) {
            window.location.href = AppConstant.DASHBOARD_URL;
        }
    }

    initPwReset(userDetail, dataType) {
        let captcha = true;
        axios.post(AppConstant.API_URL + 'account-pwreset', {dataType, userDetail, captcha})
            .then(res => {
                let message = res.data.message;
                bootbox.alert({
                    message: message,
                    callback: function () {
                        window.location.href = AppConstant.ROOT_URL;
                    }
                });
            });
    }

    componentWillMount() {
        window.$('body').addClass('hold-transition login-page');
    }

    componentWillUnmount() {
        this.props.actions.removeFlashMessage();
    }

    handleSubmit() {

        let email = $('input[name="email"]');
        let username = $('input[name="username"]');
        let dataType = 1; // input type is username by default
       // let captcha = grecaptcha.getResponse();

        $('body').find('.help-block').html('');
        $('body').find('.error').removeClass('error');

        if (email.val() == '' && username.val() == '') {
            username.addClass('error').parent().find('.help-block').addClass('error').html('Geben Sie einen Usernamen oder Ihre E-Mail-Adresse ein.');
        }

        var userData = email.val();
        if (username.val() != '') {
            userData = username.val();
        }
        if ($('.error').length == 0) {

            if (email.val() !== '') {
                dataType = 2; // set type to email
            }
            this.initPwReset(userData, dataType);
        }
        return false;
    }

	render() {

		let message = this.props.message;
		const {handleSubmit, submitting} = this.props;

		return (
			<div className="content">
				<div className="login-box">
					<div className="login-box-body">
						<h3><DigiSeal /> Konto</h3>
						<h3>Neues Passwort anfordern</h3>
						<p className="login-box-msg">Zum Erzeugen eines neuen Passworts,
						geben Sie bitte Ihren Benutzernamen oder Ihre E-Mail-Adresse ein.</p>

						<form method="post" onSubmit={handleSubmit(this.handleSubmit)}>
							<Field
								name="username"
								component={renderText}
								type="text"
								label="Benutzername"
							>
							</Field>
							<p>oder</p>
							<Field
								name="email"
								component={renderText}
								type="text"
								label="E-Mail-Adresse"
							>
							</Field>

							<div id="g-recaptcha"></div>
							<div className="help-block"></div>
							<div className="row">
								<div className="col-xs-8">
									<a href="#/" className="arrow">Zurück zur Anmeldung</a>
								</div>
								<div className="col-xs-4 button">
									<button type="submit" className="btn btn-primary btn-block btn-flat right"
											disabled={submitting}>Absenden
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

PwResetForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

/**
 * Map the state to props.
 */
function mapStateToProps(state) {
    return {
        token: state.auth.token,
        isAuthenticated: state.auth.isAuthenticated,
    }
}

/**
 * Map the actions to props.
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(_.assign({}, authService, flashMessage), dispatch)
    }
}


/**
 * Connect the component to the Redux store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'PwResetForm', // ←A Unique identifier for this form
})(PwResetForm))
