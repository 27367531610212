import React, { Component } from 'react';

class SignmeCertificate extends Component {
    render(){
        return (
            <div>
            	<h2>Siganturzertifikat beantragen</h2>
	            <p>Zum Erstellen einer qualifizierten Signatur benötigen Sie ein qualifizertes Zertifikat in ihrem sign-me Account:</p>
	            
	            <ol>
          	
            	<li><a href="#" target="_blank">Erstellen Sie jetzt ein qualifiziertes Zertifikat (verbraucht Punkte)</a></li>
                <li>
                	<a href="mailto:?subject=Einladung%20zur%20Erstellung%20eines%20Zertifikats&body=Hallo,%0D%0A%0D%0Abitte%20erstelle%20dir%20ein%20qualifiziertes%20Zertifikat%20bei%20signme:%20https://localhost:8085/#/createcertificate/CCK5OXEO/%20Dieser%20Link%20ist%20gültig%20bis%20YY-MM-DD hh.mm.%0D%0A%0D%0AViele%20Grüße">Jetzt kostenlos per E-Mai zur Zertifikatserstellung einladen.</a>
                </li>
            	
            </ol>
	            
            </div>

        );
    }
}

export default SignmeCertificate
